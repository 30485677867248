// reducers/index.js
import { combineReducers } from '@reduxjs/toolkit';
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import counterReducer from './counterSlice';
import authReducer from './authSlice';
import employeeReducer from './employeeSlice';
import signUpReducer from './signUpSlice';
import forgetPasswordReducer from './forgetPasswordSlice';
import resetPasswordReducer from './resetPasswordSlice';
import categoriesReducer from './categoriesSlice';
import applicationReducer from './applicationSlice';
import formsReducer from './formSlice';
import feedbackReducer from './feedbackSlice';
import certificateSlice from './certificateSlice';
import userdataReducer from './userSlice'
import notificationReducer from './notificationSlice';
import companiesReducer from './companiesSlice';
import cabinetReducer from './cabinetSlice';

const persistConfig = {
  key: "root",
  storage,
  whitelist: ["auth", "counter"],
};

const rootReducer = combineReducers({
  counter: counterReducer,
  forms: formsReducer,
  auth: authReducer,
  employee: employeeReducer,
  signUp: signUpReducer,
  forgetPassword: forgetPasswordReducer,
  resetPassword: resetPasswordReducer,
  categories: categoriesReducer,
  applications: applicationReducer,
  feedback: feedbackReducer,
  certificates: certificateSlice,
  userdata: userdataReducer,
  notifications : notificationReducer,
  usersdata :userdataReducer,
  companies :companiesReducer,
  cabinets : cabinetReducer
});

export default persistReducer(persistConfig, rootReducer);
