import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import classes from './style.module.css'
import { BsAsterisk } from 'react-icons/bs'
import iconFileUpload from "../../../assets/media/icon-upload-file.svg";
import iconImgUpload from "../../../assets/media/image-uploaded.png";
import iconFileDelete from "../../../assets/media/icon-delete-file.svg";

const Field = ({
    pageIndex,
    sectionIndex,
    columnIndex,
    rowIndex,
    contentIndex,
    ele,
    handleInputChange,
    formik,
    handleRadioChange,
    handleCheckboxChange,
    uniqueKey

}) => {
    // console.log({formik})
    const { top, right, bottom, left } = ele.style.margin
    switch (ele?.fieldType) {
        case 'text' || 'number':
            return (
                <>
                    <div className={classes['input-field-wrapper']} style={{ margin: `${top}px ${right}px ${bottom}px ${left}px`, width: "100%" }}>
                        <label>{ele?.label} {!!ele?.validations?.isRequired && <BsAsterisk size={8} color="red" />}</label>
                        <input
                            type={ele?.fieldType}
                            placeholder={ele?.placeHolder}
                            style={{ width: "100%" }}
                            name={uniqueKey}
                            onChange={(e) => handleInputChange(pageIndex, sectionIndex, columnIndex, rowIndex, contentIndex, e.target.value)}
                            onBlur={formik.handleBlur}
                            value={ele?.value || ''}
                        />
                        {((formik.touched[uniqueKey] && formik.errors[uniqueKey])) &&
                            <label style={{ color: 'red', position: 'absolute' }}>
                                {formik.errors[`${pageIndex}_${sectionIndex}_${columnIndex}_${rowIndex}_${contentIndex}`]}
                            </label>}
                        
                        {(ele?.discrepancy?.length > 0) &&
                            <div>  
                                <label className='alert alert-info'><b>Discrepancy: </b> {ele?.discrepancy}</label>
                            </div>
                        }
                        
                    </div>
                </>
            );
        case 'radio':
            return (
                <div className={classes['input-field-wrapper']} style={{ margin: `${top}px ${right}px ${bottom}px ${left}px`, width: "100%" }}>
                    <label>{ele?.label} {!!ele?.validations?.isRequired && <BsAsterisk size={8} color="red" />}</label>
                    <div className='d-flex'>
                        {
                            ele.radios.map((radio, radIndex) => (
                                <div key={radIndex} className='d-flex align-items-center' style={{ marginRight: 15, flex: 1 }}>
                                    <input
                                        type="radio"
                                        style={{ width: "auto", marginRight: 5 }}
                                        name={uniqueKey}
                                        checked={radio?.defaultChecked}
                                        onChange={() => handleRadioChange(pageIndex, sectionIndex, columnIndex, rowIndex, contentIndex, radIndex)}
                                        onBlur={formik.handleBlur}
                                    />
                                    <label style={{ flex: 1, margin: 0 }}>{radio.label}</label>
                                </div>
                            ))
                        }
                    </div>
                </div>
            );
        case 'checkbox':
            return (
                <div className={classes['input-field-wrapper']} style={{ margin: `${top}px ${right}px ${bottom}px ${left}px`, width: "100%" }}>
                    <label>{ele?.label} {!!ele?.validations?.isRequired && <BsAsterisk size={8} color="red" />}</label>
                    <div className='d-flex'>
                        {
                            ele.checkboxes.map((checkbox, checkIndex) => (
                                <div key={checkIndex} className='d-flex align-items-center' style={{ marginRight: 15, flex: 1 }}>
                                    <input
                                        type="checkbox"
                                        style={{ width: "auto", marginRight: 5 }}
                                        name={`${uniqueKey}_${checkIndex}`}
                                        checked={checkbox?.defaultChecked}
                                        onChange={() => handleCheckboxChange(pageIndex, sectionIndex, columnIndex, rowIndex, contentIndex, checkIndex)}
                                        onBlur={formik.handleBlur}
                                    />
                                    <label style={{ flex: 1, margin: 0 }}>{checkbox.label}</label>
                                </div>
                            ))
                        }
                    </div>
                </div>
            )
    }
}

const Text = ({ ele }) => {
    const { top, right, bottom, left } = ele.style.margin
    const Element = ele.element

    return (
        <div className={classes['text-content']} style={{ margin: `${top}px ${right}px ${bottom}px ${left}px` }}>
            <div className='upload-box'>
                <div className='' style={{ width: "100%" }}>
                    <Element style={{
                        textAlign: ele.textAlign,
                        fontSize: ele.fontSize,
                        width: "100%",
                        marginBottom: 0
                    }}
                        className={classes['text-element']}
                    >
                        {ele.content}
                    </Element>
                </div>
            </div>
        </div>
    )
}

const Table = ({ ele, cardId, colId, rowId, temp, setTemp, currentPage }) => {
    const { top, right, bottom, left } = ele.style.margin
    
    const handleUpdateColText = (e, tblColId) => {
        const value = e.target.value;

        setTemp((prevTemp) => {
            return prevTemp.map((page) => {
                if (page.id === currentPage) {
                    return {
                        ...page,
                        sections: page.sections.map((section) => {
                            if (section.id === cardId) {
                                return {
                                    ...section,
                                    column: section.column.map((col) => {
                                        if (col.id === colId) {
                                            return {
                                                ...col,
                                                row: col.row.map((row) => {
                                                    if (row.id === rowId) {
                                                        return {
                                                            ...row,
                                                            content: [
                                                                {
                                                                    ...row.content[0],
                                                                    cols: row.content[0].cols.map((col) => {
                                                                        if (col.id === tblColId) {
                                                                            return {
                                                                                ...col,
                                                                                title: value,
                                                                            };
                                                                        }
                                                                        return col;
                                                                    }),
                                                                },
                                                            ],
                                                        };
                                                    }
                                                    return row;
                                                }),
                                            };
                                        }
                                        return col;
                                    }),
                                };
                            }
                            return section;
                        }),
                    };
                }
                return page;
            });
        });
    };
    
    const handleUpdateTextValue = (e, tblRowId, tblColId) => {
        const value = e.target.value;
        setTemp((prevTemp) => {
            return prevTemp.map((page) => {
                if (page.id === currentPage) {
                    return {
                        ...page,
                        sections: page.sections.map((section) => {
                            if (section.id === cardId) {
                                return {
                                    ...section,
                                    column: section.column.map((col) => {
                                        if (col.id === colId) {
                                            return {
                                                ...col,
                                                row: col.row.map((row) => {
                                                    if (row.id === rowId) {
                                                        return {
                                                            ...row,
                                                            content: [
                                                                {
                                                                    ...row.content[0],
                                                                    rows: row.content[0].rows.map((row) => {
                                                                        if (row.id === tblRowId) {
                                                                            return {
                                                                                ...row,
                                                                                content: row.content.map((cell) => {
                                                                                    if (cell.id === tblColId) {
                                                                                        return {
                                                                                            ...cell,
                                                                                            content: value,
                                                                                        };
                                                                                    }
                                                                                    return cell;
                                                                                }),
                                                                            };
                                                                        }
                                                                        return row;
                                                                    }),
                                                                },
                                                            ],
                                                        };
                                                    }
                                                    return row;
                                                }),
                                            };
                                        }
                                        return col;
                                    }),
                                };
                            }
                            return section;
                        }),
                    };
                }
                return page;
            });
        });
    };

    return (
        <div style={{ padding: "20px 20px 0 20px", margin: `${top}px ${right}px ${bottom}px ${left}px` }}>
            <table style={{ width: "100%" }}>
                <thead>
                <tr>
                        {
                            ele.cols.map((item, i) => <th className={classes['text-element']} style={{ border: "solid 1px #ccc" }} key={i}>
                                {/* {item.title} */}
                                <input onChange={(e) => handleUpdateColText(e, item?.id)} style={{ fontWeight: 'bold' }} value={item.title} />
                            </th>)
                        }
                    </tr>
                </thead>
                <tbody>
                    {
                        ele.rows.map((item, i) => (
                            <tr key={i}>
                                {
                                    item.content.map((cell, j) => (
                                        <td className={classes['table-body-cell']} style={{ border: "solid 1px #ccc" }} key={j}>
                                            <input onChange={(e) => handleUpdateTextValue(e, item.id, cell.id)} value={cell.content} />
                                        </td>
                                    ))
                                }
                            </tr>
                        ))
                    }
                </tbody>
            </table>

            {(ele?.discrepancy?.length > 0) &&
                <div>  
                    <label className='alert alert-info w-100'><b>Discrepancy: </b> {ele?.discrepancy}</label>
                </div>
            }
        </div>
    )
}
const Upload = ({ ele, pageIndex, sectionIndex, columnIndex, rowIndex, contentIndex, radIndex, handleFileChange }) => {
    const { top, right, bottom, left } = ele.style.margin
    return (
        <div style={{ height: ele.style.height, margin: `${top}px ${right}px ${bottom}px ${left}px` }}>
            <label className={classes['upload-label']}>{ele.label}</label>
            <div className='upload-box'>
                <input onChange={(e) => handleFileChange(pageIndex, sectionIndex, columnIndex, rowIndex, contentIndex, e)} accept={ele.allowedFiles.join(', ')} id={`upload-${ele.id}`} type="file" className='d-none' />
                <label htmlFor={`upload-${ele.id}`} className={classes['upload-empty']}>
                    <img src={iconFileUpload} alt="" />
                    <span style={{ fontSize: 14 }} className='mx-2'>Click to Upload</span>
                </label>
            </div>
        </div>
    )
}
const Image = ({ ele }) => {
    const { top, right, bottom, left } = ele.style.margin
    const { height, width,display,justifycontent } = ele.style
   
    return (
        <div style={{ height: ele.style.height, margin: `${top}px ${right}px ${bottom}px ${left}px`,}}>
            <div className={classes['upload-box']} style={{display:display,justifyContent: justifycontent}}>
                <div className={classes['upload-filled']}>
                    <div className={classes['uploaded']}>
                        <img src={ele.url} alt="" style={{ height, width }} />
                        {/* <span className='ms-2'>{ele.name}</span> */}
                    </div>
                    {/* <img className='upload-del' src={iconFileDelete} alt="" /> */}
                </div>
            </div>
        </div>
    )
}

const FormContent = ({
    pageIndex,
    sectionIndex,
    columnIndex,
    rowIndex,
    contentIndex,
    element,
    handleInputChange,
    formik,
    handleRadioChange,
    handleCheckboxChange,
    uniqueKey,
    handleFileChange,
    cardId,
    item,
    rowItem,
    temp,
    currentPage,
    setTemp,
    colId,
    rowId
}) => {
    if (element?.type === 'Text') return (
        <Text 
            key={element.id} 
            ele={element} 
        />
    )
    if (element?.type === 'Table') return (
        <Table 
            key={element?.id} 
            cardId={cardId} 
            colId={colId}
            rowId={rowId}

            ele={element} 
            temp={temp} 
            currentPage={currentPage} 
            setTemp={setTemp} />
    )
    if (element?.type === 'Field') return (
        <Field
            key={element.id}
            ele={element}
            pageIndex={pageIndex}
            sectionIndex={sectionIndex}
            columnIndex={columnIndex}
            rowIndex={rowIndex}
            contentIndex={contentIndex}
            handleInputChange={handleInputChange}
            formik={formik}
            handleRadioChange={handleRadioChange}
            handleCheckboxChange={handleCheckboxChange}
            uniqueKey={uniqueKey}
        />
    )
    if (element?.type === 'Upload') return (
        <Upload 
            handleFileChange={handleFileChange} 
            key={element.id} 
            ele={element} 
            pageIndex={pageIndex}
            sectionIndex={sectionIndex}
            columnIndex={columnIndex}
            rowIndex={rowIndex}
            contentIndex={contentIndex}
            formik={formik}
            uniqueKey={uniqueKey}
        />
    )
    if (element?.type === 'Image') return (
        <Image key={element.id} ele={element} />
    )
    return (
        <></>
    )
}

export default FormContent