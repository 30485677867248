import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import classes from '../appForm/style.module.css'
import Navbar from '../../navbar';
import Header from '../../header';
import { fetchApplication, updateApplicationData, safePdf } from '../../../store/reducers/applicationSlice'
import { getSignature, getAttachments } from '../../../store/reducers/fileSlice';
import { setFormFieldData, hideAllPagesExceptCurrentFormPage } from '../../../utility/utility';
import FormHeader from '../forms/formHeader/formHeader';
import AddAttachment from './attachments';
import FormLoader from '../forms/formLoader';
import { useNavigate } from "react-router-dom";

const Draft = () => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth.user);
  const authToken = user.access_token;
  const params = useParams();
  const application = useSelector((state) => state.applications.application)
  const [pageIds, setPageIds] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [formIds, setFormIds] = useState([]);
  const [signatureImage, setSignatureImage] = useState('')
  const [inputFields, setInputFields] = useState({});
  const [signatureIds, setSignatureIds] = useState([]);
  const [isLoaded, setIsLoaded] = useState(false);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [imageData, setImageData] = useState('');
  const [showAttachments, setShowAttachments] = useState(false);

  const navigate = useNavigate();


  useEffect(() => {
    if (dispatch && params.id && authToken) {
      dispatch(fetchApplication({ id: params.id, accessToken: authToken }));
    }
  }, [dispatch, params.id, authToken])


  useEffect(() => {
    const runAsyncFunctions = async () => {
      if (isLoaded && inputFields && user) {
        await setFormFieldData(application.json_data);
        let userId = user.user.id
        let signature = await getSignature({ userId: userId, applicationId: params.id, authToken });
        if (signature) {
          setSignatureImage(signature);
        }
        let files = await getAttachments({ userId: userId, applicationId: params.id, authToken })
        files = files.map(filename => ({ name: filename }))
        setUploadedFiles(files)
      }
    };

    runAsyncFunctions();
  }, [isLoaded, user]);

  useEffect(() => {
    console.log(uploadedFiles)
  }, [uploadedFiles])
  useEffect(() => {
    if (dispatch && params.id && authToken) {
      dispatch(fetchApplication({ id: params.id, accessToken: authToken }));
    }
  }, [dispatch, params.id, authToken])

  useEffect(() => {
    if (application?.json_data && isLoaded) {
      setInputFields(JSON.parse(application.json_data));
    }
  }, [application, isLoaded])
  useEffect(() => {
    if (signatureImage != '' && signatureIds.length > 0) {
      signatureIds.forEach(id => {
        console.log("signatureImage = ", signatureImage);
        const imageInput = document.getElementById(id);
        if (imageInput && imageInput.type === 'image') {
          // Directly set the src of the image input
          let originalWidth = window.getComputedStyle(imageInput).width.replace("px", "")
          let originalTop = window.getComputedStyle(imageInput).top.replace("px", "")
          let height = (originalWidth * 114) / 179;
          imageInput.style.height = height + "px";
          imageInput.style.top = (originalTop - height) + 20 + "px";
          imageInput.src = signatureImage;
          setImageData(signatureImage);
        }
      });
    }
  }, [signatureImage])

  useEffect(() => {
    if (pageIds) {
      hideAllPagesExceptCurrentFormPage(pageIds, currentPage);
    }
  }, [pageIds])

  useEffect(() => {
    if (formIds.length > 0) {
      attachEventHandlers();
    }
  }, [formIds.length]);


  const attachEventHandlers = () => {
    let tempSignatureIds = []

    formIds.forEach(id => {
      const inputField = document.getElementById(id);
      if (inputField.type === 'image') {
        tempSignatureIds.push(id);

      } else if (inputField.type === 'checkbox') {
        console.log("checkbox");
        inputField.addEventListener('change', handleCheckBoxChange)
      } else {
        inputField.addEventListener('change', handleInputChange);
      }
    });
    setSignatureIds(tempSignatureIds);
  };
  const handleInputChange = (event) => {
    const { id, value } = event.target;

    setInputFields(prevInputFields => ({
      ...prevInputFields,
      [id]: value,
    }));
  };
  const handleCheckBoxChange = (event) => {
    const { id, checked, value } = event.target;

    setInputFields(prevInputFields => {
      // If the checkbox is checked, add it to inputFields
      if (checked) {
        return {
          ...prevInputFields,
          [id]: value,
        };
      } else {
        // If the checkbox is unchecked, remove it from inputFields
        const { [id]: removedValue, ...remainingFields } = prevInputFields;
        return remainingFields;
      }
    })
  };
  function removeDisplayNoneByIds(htmlContent, ids) {
    // Parse the HTML string into a DOM structure
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlContent, 'text/html');

    // Loop through the array of IDs
    ids.forEach(id => {
      const element = doc.getElementById(id);
      if (element && element.style.display === 'none') {
        element.style.display = '';  // Remove the 'display: none' style
      }
    });
    // JSON.parse(formData);
    Object.entries(inputFields).forEach(([id, value]) => {
      // Get the input element by ID
      const element = doc.getElementById(id);

      // Check if the element exists and is an input field
      if (element && element.tagName.toLowerCase() === 'input') {
        // Set the value of the input field
        element.setAttribute('value', value);

      }
      if (element.type === 'checkbox') {
        // Set the checked state of the checkbox
        element.setAttribute('checked', true);
      }
    });

    const modifiedHtmlContent = new XMLSerializer().serializeToString(doc);
    return modifiedHtmlContent;
  };
  const previousPage = () => {
    if (currentPage == pageIds.length + 1) {
      setShowAttachments(false);
      showDiv(currentPage - 1);
      setCurrentPage(currentPage - 1)
    } else {
      if (currentPage - 1 >= 1) {
        hideDiv(currentPage);
        showDiv(currentPage - 1);
        setCurrentPage(currentPage - 1);
      }
    }
  }

  const nextPage = () => {
    if (currentPage + 1 <= pageIds.length) {
      hideDiv(currentPage);
      showDiv(currentPage + 1);
      setCurrentPage(currentPage + 1);
    } else if (currentPage + 1 <= pageIds.length + 1) {
      hideDiv(currentPage);
      setCurrentPage(currentPage + 1);
      setShowAttachments(true);
    }
  }
  const hideDiv = (pageNo) => {
    const pageDiv = document.getElementById("page" + pageNo);
    pageDiv.style.display = "none";
  }

  const showDiv = (pageNo) => {
    const pageDiv = document.getElementById("page" + pageNo);
    pageDiv.style.display = "";
  }

  const handleSubmitWithLoading = (e) => {

    console.log(inputFields);
    let htmlForm = document.getElementById("DocumentPDF").outerHTML;
    htmlForm = removeDisplayNoneByIds(htmlForm, pageIds);

    dispatch(updateApplicationData({
      token: authToken,
      id: params.id,
      json_data: inputFields,
      htmlForm,
      state: 'in_process',
      userId: user.user.id,
      files: uploadedFiles

    }))
    dispatch(safePdf({
      htmlForm,
      user_id: user.user.id,
      id: params.id,
      token: authToken
    }))
    navigate('/in-process-applications');

  };

  const saveToDrafts = (e) => {
    console.log(inputFields);
    let htmlForm = document.getElementById("DocumentPDF").outerHTML;
    htmlForm = removeDisplayNoneByIds(htmlForm, pageIds);

    dispatch(updateApplicationData({
      token: authToken,
      id: params.id,
      json_data: inputFields,
      htmlForm,
      state: e,
      userId: user.user.id,
      files: uploadedFiles
    }))

    dispatch(safePdf({
      htmlForm,
      user_id: user.user.id,
      id: params.id,
      token: authToken
    }))
  }
  const handlePageChange = (number) => {
    if (number > 0 && number <= pageIds.length + 1) {
      if (number == pageIds.length + 1) {
        hideDiv(currentPage);
        setCurrentPage(number);
        setShowAttachments(true);
      } else {
        if (currentPage == pageIds.length + 1) {
          setShowAttachments(false)
          showDiv(number)
          setCurrentPage(number)
        } else {
          hideDiv(currentPage);
          showDiv(number);
          setCurrentPage(number);
        }
      }
    }
  }
  return (
    <div>
      <Navbar />
      <Header />
      <div className='wrapper'>
        <div className='boxAppBoard boxWhite'>
          <div className="stickyFormHeader">
            <FormHeader applicationName={application.name} nextPage={nextPage} previousPage={previousPage}
              currentPage={currentPage} totalPages={pageIds.length + 1} handleSubmitWithLoading={handleSubmitWithLoading}
              saveToDrafts={saveToDrafts} handlePageChange={handlePageChange} />
          </div>

          <div className={classes['board-content']}>
            {application && <FormLoader applicationName={application.name} setFormIds={setFormIds} setPageIds={setPageIds} setIsLoaded={setIsLoaded} />}

          </div>

          {showAttachments && <AddAttachment signatureIds={signatureIds} setUploadedFiles={setUploadedFiles} uploadedFiles={uploadedFiles} />}
        </div>
      </div>
    </div>
  )
}
export default Draft