import { useState, useRef, useEffect } from 'react';
import Navbar from '../../navbar';
import Header from '../../header';
import SignatureCanvas from 'react-signature-canvas';

const AddAttachment = (props) => {
    const [affidavitFile, setAffidavitFile] = useState(null);
    const [supportedDocuments, setSupportedDocuments] = useState({});
    const [signatureImage, setSignatureImage] = useState(null);
    const [showSignaturePad, setShowSignaturePad] = useState(false);
    const sigCanvas = useRef();
   
    const isInternalUpdate = useRef(false); // Track if the update is internal

    // Sync state with props.uploadedFiles
    useEffect(() => {
      if (!isInternalUpdate.current && props.uploadedFiles) {
        const newAffidavitFile = props.uploadedFiles.find((file) =>
          file.name.startsWith('formAffidavitFile')
        );
  
        const newSupportedDocuments = props.uploadedFiles
          .filter((file) => file.name.startsWith('supportedDocument'))
          .reduce((acc, file, index) => {
            acc[`doc${index + 1}`] = file; // Create key like doc1, doc2, etc.
            return acc;
          }, {});
  
        const newSignatureImage = props.uploadedFiles.find((file) =>
          file.name.startsWith('formSignatureUser')
        );
  
        setAffidavitFile(newAffidavitFile || null);
        setSupportedDocuments(newSupportedDocuments);
        setSignatureImage(newSignatureImage || null);
      }
  
      // Reset internal update flag after syncing
      isInternalUpdate.current = false;
    }, [props.uploadedFiles]);
  
    // Sync state changes to props.uploadedFiles
    useEffect(() => {
      const newFiles = [
        affidavitFile,
        ...Object.values(supportedDocuments),
        signatureImage,
      ].filter(Boolean);
  
      if (JSON.stringify(newFiles) !== JSON.stringify(props.uploadedFiles)) {
        isInternalUpdate.current = true; // Mark this update as internal
        props.setUploadedFiles(newFiles);
      }
    }, [affidavitFile, supportedDocuments, signatureImage]);
      

    const validateFile = (file, allowedTypes = [], maxSizeMB = 18) => {
        if (!allowedTypes.includes(file.type)) {
            alert('Invalid file type.');
            return false;
        }
        if (file.size > maxSizeMB * 1024 * 1024) {
            alert(`File size exceeds the ${maxSizeMB}MB limit.`);
            return false;
        }
        return true;
    };

    const renameFile = (file, prefix) => {
        return new File([file], `${prefix+"_"+file.name}`, { type: file.type });
    };

    const handleAffidavitChange = (e) => {
        const selectedFile = e.target.files?.[0];
        if (selectedFile && validateFile(selectedFile, ['application/pdf','image/png', 'image/jpeg'])) {
            const renamedFile = renameFile(selectedFile, 'formAffidavitFile');
            setAffidavitFile(renamedFile);
        }
    };

    const handleMultipleFileChange = (e) => {
        const selectedFiles = Array.from(e.target.files);
        const newFiles = selectedFiles.reduce((acc, file, index) => {
            const renamedFile = renameFile(file, `supportedDocument_${index + 1}`);
            acc[index + 1] = renamedFile;
            return acc;
        }, {});
        setSupportedDocuments((prevFiles) => ({ ...prevFiles, ...newFiles }));
    };

    const removeFile = (fileIndex) => {
        setSupportedDocuments((prevFiles) => {
            const updatedFiles = { ...prevFiles };
            delete updatedFiles[fileIndex];
            return updatedFiles;
        });
    };

    const saveSignature = () => {
        const trimmedDataUrl = sigCanvas.current.getTrimmedCanvas().toDataURL('image/png');
        // Convert DataURL to Blob (binary large object)
        fetch(trimmedDataUrl)
            .then(res => res.blob())
            .then(blob => {
                const signatureFile = new File([blob], 'formSignatureUser_esign.png', { type: 'image/png' });

                // Pass the file to the parent component or handle it here
                setSignatureForm(signatureFile);

                setShowSignaturePad(false); // Hide the signature pad after saving
                setSignatureImage(signatureFile);

            });
    };
    const setSignatureForm = (file) => {
        const reader = new FileReader();
        reader.onload = (e) => {
            props.signatureIds.forEach(id => {
                const imageInput = document.getElementById(id);
                if (imageInput && imageInput.type === 'image') {
                    imageInput.src = e.target.result;
                }
            });
        };
        reader.readAsDataURL(file);
    }

    const clearCanvas = () => {
        sigCanvas.current.clear();
    };

    const handleSignatureChange = (e) => {
        const selectedFile = e.target.files?.[0];
        if (selectedFile && validateFile(selectedFile, ['image/png', 'image/jpeg'])) {
            const renamedFile = renameFile(selectedFile, 'formSignatureUser');
            setSignatureImage(renamedFile);
        }
    };

    return (
        <div>
            <Navbar />
            <Header />
            <div className="attachment">
                <h2>Required Attachments</h2>
                <div className="fileUpload">
                    {/* Affidavit File Upload */}
                    <div className="form-group">
                        <input
                            type="file"
                            name="file"
                            id="file-1"
                            className="input-file"
                            onChange={handleAffidavitChange}
                        />
                        <label htmlFor="file-1" className="btn btn-tertiary">
                            <span>
                                {affidavitFile?.name?.replace(/^formAffidavitFile_/,"") || 'Attach Affidavit'}
                            </span>
                        </label>
                    </div>

                    {/* Supported Documents Upload */}
                    <div className="form-group">
                        <input
                            type="file"
                            name="file"
                            id="file-2"
                            className="input-file"
                            multiple
                            onChange={handleMultipleFileChange}
                        />
                        <label htmlFor="file-2" className="btn btn-tertiary">
                            <span>Attach Supported Documents</span>
                        </label>
                        <ul className="file-list">
                            {Object.entries(supportedDocuments).map(([key, file]) => (
                                <li key={key} className="file-item">
                                    {file.name?.replace(/^supportedDocument_/, '')}
                                    <button
                                        className="remove-btn"
                                        onClick={() => removeFile(key)}
                                        style={{
                                            marginLeft: '10px',
                                            color: '#ff4d4d',
                                            background: 'none',
                                            border: 'none',
                                            cursor: 'pointer',
                                        }}
                                    >
                                        Remove
                                    </button>
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
            </div>

            {/* Signature Section */}
            <div className="attachment">
                <div className="fileUpload">
                    <div className="form-group d-flex">
                        <div>
                            <input
                                type="file"
                                name="file"
                                id="signature"
                                className="input-file"
                                onChange={handleSignatureChange}
                            />
                            <label htmlFor="signature" className="btn btn-tertiary">
                                <span>
                                     {signatureImage?.name?.replace(/^formSignatureUser_/, '') || 'Attach Signature Image'}
                                </span>
                            </label>
                        </div>
                        <div>
                            <button
                                type="button"
                                style={{
                                    backgroundColor: 'transparent',
                                    color: '#114df2',
                                    padding: '5px',
                                    border: '1px dotted #114df2',
                                    borderRadius: 5,
                                    width: '113px',
                                    height: '38.8px',
                                    fontSize: '16px',
                                    marginLeft: '10px',
                                }}
                                onClick={() => setShowSignaturePad(true)}
                            >
                                e-Signature
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            {/* Signature Pad */}
            {showSignaturePad && (
                <div>
                    <h2>Signature Pad</h2>
                    <div style={{ border: '1px solid black', width: '400px', height: '200px' }}>
                        <SignatureCanvas
                            ref={sigCanvas}
                            penColor="black"
                            canvasProps={{ width: 400, height: 200, className: 'sigCanvas' }}
                        />
                    </div>
                    <div style={{ marginTop: '10px' }}>
                        <button
                            onClick={clearCanvas}
                            style={{
                                backgroundColor: '#ff4d4d',
                                color: '#fff',
                                padding: '5px 10px',
                                border: 'none',
                                borderRadius: '5px',
                                marginRight: '10px',
                            }}
                        >
                            Clear
                        </button>
                        <button
                            onClick={saveSignature}
                            style={{
                                backgroundColor: '#4caf50',
                                color: '#fff',
                                padding: '5px 10px',
                                border: 'none',
                                borderRadius: '5px',
                            }}
                        >
                            Save
                        </button>
                    </div>
                </div>
            )}
        </div>
    );
};

export default AddAttachment;
