import React, { useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import logo from "../../../assets/media/logo.png";
import iconHide from "../../../assets/media/icon-hide.png";
import { resetPassword } from '../../../store/reducers/resetPasswordSlice'

const Reset = () => {


    const navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation();

    const queryParams = new URLSearchParams(location.search);
    const passwordresetcode = queryParams.get('passwordresetcode');   

    const loading = useSelector((state) => state.resetPassword.loading);


    const initialValues = {
        password: '',
        new_password: '',
    };

    const validationSchema = Yup.object({
        password: Yup.string().required('password is required'),
        new_password: Yup.string().required('Re-Enter your password'),
    });


    const formik = useFormik({
        initialValues,
        validationSchema,
        onSubmit: (values) => {
            dispatch(resetPassword({
                password: values.password,
                new_password: values.new_password,
                passwordresetcode: passwordresetcode,
            }));
        },
    });

    const { values, handleChange, handleSubmit, touched } = formik;

    return (
        <div className="App">
            <div className="container">
                <div className='landingWrapper'>

                    <div className='lp-pwMain'>
                        <div className='lp-pwWrapper'>

                            <img src={logo} className="logo" alt="logo" />
                            <h2>Reset Password</h2>
                            <p>Create your new password to signin</p>

                            <form className='gb-login-form' onSubmit={handleSubmit}>

                                <div className="gb-field-wrap">
                                    <label>New Password</label>
                                    <input
                                        name='password'
                                        className="form-class"
                                        type="password"
                                        placeholder=""
                                        value={values.password}
                                        onChange={handleChange}
                                    />
                                    <img src={iconHide} className="iconField" alt="" />
                                </div>
                                <div className="gb-field-wrap">
                                    <label>Re-Enter Password</label>
                                    <input
                                        name='new_password'
                                        className="form-class"
                                        type="password"
                                        placeholder=""
                                        value={values.new_password}
                                        onChange={handleChange}
                                    />
                                    <img src={iconHide} className="iconField" alt="" />
                                </div>
                                <div>
                                    {touched.new_password && values.new_password !== values.password ? <p className='text-danger'>password not matched</p> : ""}
                                </div>
                                <div className='gb-form-footer'>
                                    <button type='submit' disabled={touched.new_password && values.new_password !== values.password} className='form-submit'>
                                        {loading ?
                                            <>
                                                <div class="text-xs spinner-border text-light" style={{ width: '1rem', height: '1rem' }} role="status">
                                                </div>
                                                <span class="sr-only ml-5"> Loading...</span>
                                            </>
                                            :
                                            "Reset Password"
                                        }
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    )
}

export default Reset