import React from 'react'
import iconRenewel2 from "../../../assets/media/icon-renewel-2.svg";
import iconTimeSquare from "../../../assets/media/icon-time-square.svg";
import iconSwap from "../../../assets/media/icon-swap.svg";
import iconTick from "../../../assets/media/icon-tick.svg";
import iconSearch from "../../../assets/media/icon-search.svg";
import iconEmpty from "../../../assets/media/empty.gif";
import iconUnion from "../../../assets/media/icon-union.svg";



import { Link } from 'react-router-dom';
import Navbar from '../../navbar';
import Header from '../../header';

const Dashboard2 = () => {
    return (
        <div>
            <Navbar />
            <Header />
            <div className='wrapper'>
                <div class="alert alert-warning alert-dismissible fade show alertProfile" role="alert">
                    <p><img src={iconUnion} alt="" /> Hello Marvin McKinney please complete your <Link to={'/profile'}>profile</Link></p>
                    <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                </div>
                <div className='row01'>
                    <div className='boxApp'>
                        <h2>My Applications</h2>
                        <div className='appStates'>
                            <div className='appState stateProcess'>
                                <h3>
                                    <img src={iconRenewel2} alt='' />
                                    <span>08</span>
                                </h3>
                                <h4>In Process</h4>
                                <Link to={'/'}>View All</Link>
                            </div>
                            <div className='appState statePending'>
                                <h3>
                                    <img src={iconTimeSquare} alt='' />
                                    <span>13</span>
                                </h3>
                                <h4>Pending</h4>
                                <Link to={'/'}>View All</Link>
                            </div>
                            <div className='appState stateDiscrepancies'>
                                <h3>
                                    <img src={iconSwap} alt='' />
                                    <span>05</span>
                                </h3>
                                <h4>Discrepancies</h4>
                                <Link to={'/'}>View All</Link>
                            </div>
                            <div className='appState stateApproved'>
                                <h3>
                                    <img src={iconTick} alt='' />
                                    <span>105</span>
                                </h3>
                                <h4>Approved</h4>
                                <Link to={'/'}>View All</Link>
                            </div>
                        </div>
                    </div>
                    <div className='boxForm'>
                        <h2>Total Submitted Forms</h2>

                    </div>
                </div>
                <div className='boxAppBoard boxWhite'>
                    <div className='boardHeader'>
                        <div className='boardHeadLeft'>
                            <div className='boardFilter'>
                                <div class="form-check form-check-inline">
                                    <input class="form-check-input" type="checkbox" value="All" checked />
                                    <label class="form-check-label">All</label>
                                </div>
                                <div class="form-check form-check-inline">
                                    <input class="form-check-input" type="checkbox" value="In Process" />
                                    <label class="form-check-label">In Process</label>
                                </div>
                                <div class="form-check form-check-inline">
                                    <input class="form-check-input" type="checkbox" value="Discrepancies" />
                                    <label class="form-check-label">Discrepancies</label>
                                </div>
                                <div class="form-check form-check-inline">
                                    <input class="form-check-input" type="checkbox" value="Pending" />
                                    <label class="form-check-label">Pending</label>
                                </div>
                                <div class="form-check form-check-inline">
                                    <input class="form-check-input" type="checkbox" value="Approved" />
                                    <label class="form-check-label">Approved</label>
                                </div>
                            </div>
                        </div>
                        <div className='boardHeadRight'>
                            <div className='searchForm'>
                                <input type='text' placeholder='input search text' />
                                <button><img src={iconSearch} alt='' /></button>  
                            </div>
                            <div className='datePicker'>
                            </div>
                        </div>
                    </div>
                    <div className='boardContent'>
                        <div className='boardThead'>
                            <div className='boardCol w-85'>
                                S.NO.
                            </div>
                            <div className='boardCol w-165'>
                                Application No.
                            </div>
                            <div className='boardCol w-adj1'>
                                Application Name
                            </div>
                            <div className='boardCol w-165'>
                                Submitted Date
                            </div>
                            <div className='boardCol w-165'>
                                Registered Date
                            </div>
                            <div className='boardCol w-165'>
                                Status
                            </div>
                            <div className='boardCol w-165'>
                                Attachments
                            </div>
                            <div className='boardCol w-110'>

                            </div>
                        </div>
                        <div className='boardNoForms'>
                            <img src={iconEmpty} alt='' />
                            <p>You have not filled any form yet</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default Dashboard2