// import React, { useState } from 'react';
// import { Link } from 'react-router-dom';

// import iconCaretLeft from "../../../assets/media/icon-caret-left.svg";
// import iconCaretRight from "../../../assets/media/icon-caret-right.svg";
// import iconCaretDown from "../../../assets/media/icon-caret-down.svg";

// function Pagination({ setCurrentPage, currentPage = 1, totalPages, itemsPerPage = 10 }) {
//     const [_currentPage, _setCurrentPage] = useState(currentPage);
//     const [_totalPages, _setTotalPages] = useState(totalPages);
//     const [_itemsPerPage, _setItemsPerPage] = useState(itemsPerPage);
//     const pageNumbers = Array.from({ length: _totalPages }, (_, index) => index + 1);

//     const handlePageClick = (pageNumber) => {
//         if (pageNumber > 0 && pageNumber < _totalPages + 1) {
//             _setCurrentPage(Number(pageNumber));
//             setCurrentPage(Number(pageNumber));
//         }
//     };

//     return (
//         <div className='boardFooter'>
//             <div className='pagination'>
//                 <Link
//                     className={`disabled ${_currentPage > 1 ? 'active' : 'hidden'}`}
//                     onClick={() => handlePageClick(_currentPage - 1)}
//                 >
//                     <img src={iconCaretLeft} alt='' />
//                 </Link>
//                 {[...Array(totalPages())].map((_, i) => (
//                     <Link
//                         key={i}
//                         className={`${_currentPage === i + 1 ? 'active' : ''}`}
//                         onClick={() => handlePageClick(i + 1)}
//                     >
//                         {i + 1}
//                     </Link>
//                 ))}
//                 <Link
//                     className={`disabled ${_currentPage < _totalPages ? 'active' : 'hidden'}`}
//                     onClick={() => handlePageClick(_currentPage + 1)}
//                 >
//                     <img src={iconCaretRight} alt='' />
//                 </Link>
//                 <Link className='wAuto'>
//                     {_itemsPerPage} / page <img className='ml-6' src={iconCaretDown} alt='' />
//                 </Link>
//                 <span>Goto</span>
//                 <input type='number' min={1} max={_totalPages} onChange={(e) => handlePageClick(e.target.value)} />
//             </div>
//         </div>
//     );
// }


// export default Pagination;

import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import iconCaretLeft from "../../../assets/media/icon-caret-left.svg";
import iconCaretRight from "../../../assets/media/icon-caret-right.svg";
import iconCaretDown from "../../../assets/media/icon-caret-down.svg";

function Pagination({ setCurrentPage, currentPage = 1, totalPages, itemsPerPage = 10 }) {
    const [_currentPage, _setCurrentPage] = useState(currentPage);
    const [_itemsPerPage, _setItemsPerPage] = useState(itemsPerPage);

    const handlePageClick = (pageNumber) => {
        if (pageNumber > 0 && pageNumber < totalPages() + 1) {
            _setCurrentPage(Number(pageNumber));
            setCurrentPage(Number(pageNumber));
        }
    };

    return (
        <div className='boardFooter'>
            <div className='pagination'>
                <Link
                    className={`disabled ${_currentPage > 1 ? 'active' : 'hidden'}`}
                    onClick={() => handlePageClick(_currentPage - 1)}
                >
                    <img src={iconCaretLeft} alt='' />
                </Link>
                {[...Array(totalPages())].map((_, i) => (
                    <Link
                        key={i}
                        className={`${_currentPage === i + 1 ? 'active' : ''}`}
                        onClick={() => handlePageClick(i + 1)}
                    >
                        {i + 1}
                    </Link>
                ))}
                <Link
                    className={`disabled ${_currentPage < totalPages() ? 'active' : 'hidden'}`}
                    onClick={() => handlePageClick(_currentPage + 1)}
                >
                    <img src={iconCaretRight} alt='' />
                </Link>
                <Link className='wAuto'>
                    {_itemsPerPage} / page <img className='ml-6' src={iconCaretDown} alt='' />
                </Link>
                <span>Goto</span>
                <input type='number' min={1} max={() => totalPages()} onChange={(e) => handlePageClick(e.target.value)} />
            </div>
        </div>
    );
}


export default Pagination;
