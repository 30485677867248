import React, { useEffect, useState } from 'react'
import iconFileDownload from "../../../assets/media/icon-file-download.svg";
import iconSearch from "../../../assets/media/icon-search.svg";
import iconPaper from "../../../assets/media/icon-paper.svg";

import { Link } from 'react-router-dom';
import Navbar from '../../navbar';
import Header from '../../header';
import Pagination from '../dashboard/appPagination';
import { useDispatch, useSelector } from 'react-redux';
import { fetchApplications } from '../../../store/reducers/applicationSlice';
import {handleFilesDownload} from '../../../store/reducers/fileSlice';

const Renewals = () => {
    const dispatch = useDispatch();
    const user = useSelector((state) => state.auth.user);
    const authToken = user.access_token;

    const applications = useSelector((state) => state.applications);

    const [searchQuery, setSearchQuery] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const getallUserdata = useSelector((state) => state.userdata.getalluserdata);
    const [filteredApplication, setFilteredApplication] = useState([]);

    useEffect(() => {
        dispatch(fetchApplications({ accessToken: authToken }));
    }, [authToken, dispatch]);

   
    useEffect(() => {
        if (applications.data?.length > 0 && getallUserdata?.length > 0) {
            const mergedData = applications.data.map((appItem) => {
                const userItem = getallUserdata.find((userData) => userData.id === appItem.user_id);
                if (userItem) {
                    return {
                        ...appItem,
                        applicationId: appItem.id, 
                        ...userItem,
                        userId: userItem.id, 
                    };
                }
                return { ...appItem, applicationId: appItem.id }; 
            });
    
            const filteredData = mergedData.filter((item) =>
            item.company_id === user.user.company_id &&
            item.state === "renewal" &&
            Object.values(item).some((value) =>
                value && value.toString().toLowerCase().includes(searchQuery.toLowerCase())
            )
        );
    
            setFilteredApplication(filteredData);
            console.log(filteredData, 'Filtered Data');
        }
    }, [searchQuery, applications.data, getallUserdata, user.user.company_id]);

    const handleSearchInputChange = (e) => {
        setSearchQuery(e.target.value);
    };

    const getTotalPages = () => {
        if (Number.isInteger(filteredApplication.length / 10)) return filteredApplication.length / 10;
        return Math.floor(filteredApplication.length / 10) + 1;
    };

    const handleDownload = (item) => {
        const applicationId = item.applicationId
        const userId = item.userId
        handleFilesDownload({userId,applicationId,authToken});
    };


    return (
        <div>
            <Navbar />
            <Header />
            <div className='wrapper'>
                <div className='boxAppBoard boxWhite'>
                    <div className='boardHeader'>
                        <div className='boardHeadLeft'>
                            <h2>Renewals</h2>
                        </div>
                        <div className='boardHeadRight'>
                            <div className='searchForm'>
                                <input type='text' placeholder='input search text' onChange={handleSearchInputChange} value={searchQuery} />
                                <button><img src={iconSearch} alt='' /></button>
                            </div>
                            <div className='datePicker'>
                            </div>
                        </div>
                    </div>
                    <div className='boardContent'>
                        <div className='boardThead'>
                            <div className='boardCol w-85'>
                                S.NO.
                            </div>
                            <div className='boardCol w-165'>
                                Application No.
                            </div>
                            <div className='boardCol w-adj4'>
                                Application Name
                            </div>
                            <div className='boardCol w-165'>
                                Due Date
                            </div>
                            <div className='boardCol w-165'>
                                Last Registered
                            </div>
                            <div className='boardCol w-165'>
                            </div>
                            <div className='boardCol w-110'>

                            </div>
                        </div>
                        {filteredApplication.sort((a, b) => b.applicationId - a.applicationId).slice((currentPage - 1) * 10, currentPage * 10).map((item, index) => (
                            <div className='boardTrow' key={item.id} >
                                <div className='boardCol w-85'>
                                    {index + 1}
                                </div>
                                <div className='boardCol w-165'>
                                    {`GB-${item.applicationId}`}
                                </div>
                                <div className='boardCol w-adj4'>
                                    <div className='boardTitle'>
                                        <strong>{item.name}</strong>
                                        <span>{item.form}</span>
                                    </div>
                                </div>
                                <div className='boardCol w-165'>
                                {item?.updated_at.slice(0, 10)}
                                </div>
                                <div className='boardCol w-165'>
                                {item?.updated_at.slice(0, 10)}
                                </div>
                                <div className='boardCol w-165'>
                                    <div className='boardDownload'>
                                       
                                    </div>
                                </div>
                                <div className='boardCol w-110'>
                                    <div className='boardActions'>
                                        <Link to={'/'}><img src={iconPaper} alt='' /></Link>
                                        <img onClick={() => handleDownload(item)} src={iconFileDownload} alt='' />
                                    </div>
                                </div>
                            </div>
                        ))}


                    </div>
                    <Pagination
                        currentPage={currentPage}
                        setCurrentPage={setCurrentPage}
                        totalPages={getTotalPages}
                    />
                </div>
            </div>
        </div>
    )
}
export default Renewals