import React from 'react';
import iconUpload from "../../../assets/media/icon-upload.svg";
import imageUploaded from "../../../assets/media/image-uploaded.png";
import iconBack from "../../../assets/media/icon-back.svg";
import { Link } from 'react-router-dom';
import Navbar from '../../navbar';
import Header from '../../header';

const GamingLicence = () => {
    return (
        <div>
            <Navbar />
            <Header />
            <div className='wrapper'>
                <div className='boxAppBoard boxWhite'>
                    <div className='boardHeader noMg'>
                        <div className='boardHeadLeft'>
                            <h2>Gaming Licence</h2>
                        </div>
                        <div className='boardHeadRight'>
                        </div>
                    </div>
                </div>
                <div className='divFiles'>
                    <div className='headFiles'>
                        <div className='leftFiles'>
                            <div className='breadcrumbFiles'>
                                <Link className='back' to={'/'}><img src={iconBack} alt="" /></Link>
                                <Link to={'/file-cabinet'}>File Cabinet</Link>
                                <span>/</span>
                                Gaming Licence
                            </div>
                        </div>
                        <div className='rightFiles'>
                        </div>
                    </div>
                    <div className='rowAttachments'>
                        <div className='colAttachment upload'>
                            <img src={iconUpload} alt="" />
                            <span>Upload File</span>
                        </div>
                        <div className='colAttachment uploaded'>
                            <img src={imageUploaded} alt="" />
                        </div>
                        <div className='colAttachment uploaded'>
                            <img src={imageUploaded} alt="" />
                        </div>
                        <div className='colAttachment uploaded'>
                            <img src={imageUploaded} alt="" />
                        </div>
                        <div className='colAttachment uploaded'>
                            <img src={imageUploaded} alt="" />
                        </div>
                        <div className='colAttachment uploaded'>
                            <img src={imageUploaded} alt="" />
                        </div>
                        <div className='colAttachment uploaded'>
                            <img src={imageUploaded} alt="" />
                        </div>
                        <div className='colAttachment uploaded'>
                            <img src={imageUploaded} alt="" />
                        </div>
                        <div className='colAttachment uploaded'>
                            <img src={imageUploaded} alt="" />
                        </div>
                        <div className='colAttachment uploaded'>
                            <img src={imageUploaded} alt="" />
                        </div>
                        <div className='colAttachment uploaded'>
                            <img src={imageUploaded} alt="" />
                        </div>
                        <div className='colAttachment uploaded'>
                            <img src={imageUploaded} alt="" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default GamingLicence