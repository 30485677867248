// reducers/applicationSlice.js
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { showError, showSuccess } from "../../helpers/toast";

const API = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_URL,
});

API.interceptors.response.use(
  (response) => {
    return Promise.resolve(response);
  },
  (error) => {
    showError(error.response?.data?.error ?? error.message);
    return Promise.reject(error);
  }
);

const initialState = {
  fetchLoading: false,
  fetchError: null,
  message: null,
  data: [],
  application: {},
};

export const fetchApplications = createAsyncThunk(
  "fetchApplications",
  async (data) => {
    try {
      const config = {
        headers: {
          "ngrok-skip-browser-warning": "69420",
          "Content-Type": "application/json",
          Authorization: `${data.accessToken}`,
        },
      };
      const response = await API.get("/applications", config);
      return response.data;
    } catch (error) {
      throw new Error("Failed to load applications");
    }
  }
);
export const fetchApplication = createAsyncThunk(
  "fetchApplication",
  async (data) => {
    try {
      const config = {
        headers: {
          "ngrok-skip-browser-warning": "69420",
          "Content-Type": "application/json",
          Authorization: `${data.accessToken}`,
        },
      };
      const response = await API.get("/applications/" + data?.id, config);
      return response.data;
    } catch (error) {
      throw new Error("Failed to load application");
    }
  }
);
export const postApplication = createAsyncThunk(
  "postApplication",
  async (data) => {
    try {
      const formData = new FormData();
      const config = {
        headers: {
          "ngrok-skip-browser-warning": "69420",
          "Content-Type": "multipart/form-data",
          Authorization: `${data.token}`,
        },
      };
      formData.append("form", data?.form);
      formData.append("json_data", JSON.stringify(data?.json_data)); // Convert JSON to string
      formData.append("name", data?.name);
      formData.append("category", data?.category);
      formData.append("user_id", data?.user_id);
      formData.append("state", data?.state);
      if (data?.files && data.files.length > 0) {
        data.files.forEach((file, index) => {
          formData.append(`files`, file);
        });
      }
      console.log({ data });

      const response = await API.post("/applications", formData, config);

      showSuccess("Create Successfully!");
      return response.data;
    } catch (error) {
      throw new Error("Failed to load applications");
    }
  }
);
export const updateApplication = createAsyncThunk(
  "updateApplication",
  async (data) => {
    try {
      const config = {
        headers: {
          "ngrok-skip-browser-warning": "69420",
          "Content-Type": "application/json",
          Authorization: `${data.accessToken}`,
        },
      };

      const payload = {};
      if (data?.json_data) payload.json_data = JSON.stringify(data?.json_data);
      if (data?.dueDate) payload.dueDate = data.dueDate;
      if (data?.descripencie) payload.descripencie = data.descripencie;
      if (data?.htmlForm) payload.htmlForm = data?.htmlForm;

      const response = await API.put(
        `/applications/${data?.id}`,
        payload,
        config
      );
      showSuccess("Your application has been updated!");
      return response.data;
    } catch (error) {
      throw new Error("Failed to load applications");
    }
  }
);

export const safePdf = createAsyncThunk("safePdf", async (data) => {
  try {
    const config = {
      headers: {
        "ngrok-skip-browser-warning": "69420",
        "Content-Type": "application/json",
        Authorization: `${data.token}`,
      },
    };

    const payload = {};

    if (data?.htmlForm) payload.htmlForm = data?.htmlForm;
    if (data?.user_id) payload.user_id = data?.user_id;

    const response = await API.post(
      `/applications/${data?.id}/savepdf`,
      payload,
      config
    );
    showSuccess("Your Pdf is saved!");
    return response.data;
  } catch (error) {
    throw new Error("Failed to load applications");
  }
});

export const uploadFile = createAsyncThunk("uploadFile", async (data) => {
  const { accessToken, payload } = data;

  try {
    const config = {
      headers: {
        "ngrok-skip-browser-warning": "69420",
        "Content-Type": "multipart/form-data",
        Authorization: `${accessToken}`,
      },
    };
    const response = await API.post(
      "/applications/upload-file",
      payload,
      config
    );

    return response.data;
  } catch (error) {
    throw new Error("Failed to upload file");
  }
});
export const updateApplicationData = createAsyncThunk(
  "updateApplicationData",
  async (data) => {
    try {
      const formData = new FormData();
      const config = {
        headers: {
          "ngrok-skip-browser-warning": "69420",
          "Content-Type": "multipart/form-data",
          Authorization: `${data.token}`,
        },
      };
      formData.append("form", data?.form);
      formData.append("json_data", JSON.stringify(data?.json_data)); // Convert JSON to string
      formData.append("state", data?.state);

      if (data?.files && data.files.length > 0) {
        data.files.forEach((file, index) => {
          formData.append(`files`, file);
        });
      }

      const response = await API.put(
        `/applications/${data?.id}/updateApplication`,
        formData,
        config
      );

      showSuccess("Updated Successfully!");
      return response.data;
    } catch (error) {
      throw new Error("Failed to load applications");
    }
  }
);

// export const getUserCertificates = async (data) => {
//   try {
//     const config = {
//       headers: {
//         'Content-Type': 'multipart/form-data',
//         'Authorization': `${accessToken}`,
//       },
//     };
//     const response = await API.post('/applications/upload-file', payload, config);
    
//     return response.data;
//   } catch (error) {
//     throw new Error('Failed to upload file');
//   }
// });

// export const updateApplicationData = createAsyncThunk('updateApplicationData', async (data) => {
//   try {
//     const formData = new FormData();
//     const config = {
//       headers: {
//         'Content-Type': 'multipart/form-data',
//         'Authorization': `${data.token}`,
//       },
//     };
//     formData.append('form', data?.form);
//     formData.append('json_data', JSON.stringify(data?.json_data)); // Convert JSON to string
//     formData.append('state',data?.state);
//     formData.append('userId',data.userId)
//     const previousFiles = data?.files
//     .filter(file => !file.hasOwnProperty("Type")) // Exclude objects with `Type`
//     .map(file => file.name);

//     if(previousFiles){
//       formData.append('previousFiles',previousFiles)
//     }
    
//     if (data?.files && data.files.length > 0) {
//       data.files.forEach((file) => {
//         if(file?.type){
//           formData.append(`files`, file);
//         }
//       });
//     }

//     const response = await API.put(
//       `/applications/${data?.id}/updateApplication`,
//       formData,
//       config
//     );

//     if (!response.ok) {
//       throw new Error("Failed to Get Certificates");
//     }

//     const responseData = await response.json();
//     console.log(responseData);
//     return responseData;
//   } catch (error) {
//     throw new Error('Failed to load applications');
//   }
// });

export const getUserCertificates = async (data) => {
  try {     
      // Call the API
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}applications/${data?.userId}/certificates`, {
          method: 'GET',
          headers: {
            'Authorization': `${data.authToken}`,
          },
        });
    
        if (!response.ok) {
          return []
        }

        const responseData = await response.json();
        console.log(responseData);
        return responseData;

  } catch (error) {
    console.error('Error Getting data:', error);
  }
};

const applicationSlice = createSlice({
  name: "applications",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchApplications.pending, (state) => {
        state.fetchLoading = true;
      })
      .addCase(fetchApplications.fulfilled, (state, action) => {
        state.fetchLoading = false;
        state.data = action.payload;
        state.fetchError = null;
      })
      .addCase(fetchApplications.rejected, (state, action) => {
        state.fetchLoading = false;
        state.fetchError = action.error.message;
        state.data = null;
        state.message = null;
      })

      .addCase(fetchApplication.pending, (state) => {
        state.fetchLoading = true;
      })
      .addCase(fetchApplication.fulfilled, (state, action) => {
        console.log("action", action.payload);
        state.fetchLoading = false;
        state.application = action.payload;
        state.fetchError = null;
      })
      .addCase(fetchApplication.rejected, (state, action) => {
        state.fetchLoading = false;
        state.fetchError = action.error.message;
        state.application = null;
      });
  },
});

export default applicationSlice.reducer;
