// reducers/categoriesSlice.js
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const API = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_URL,
});

const initialState = {
  fetchLoading: false,
  fetchError: null,
  message: null,
  data: [],
};

export const fetchCategories = createAsyncThunk(
  "fetchCategories",
  async (data) => {
    try {
      const config = {
        headers: {
          "ngrok-skip-browser-warning": "69420",
          "Content-Type": "application/json",
          Authorization: `${data.accessToken}`,
        },
      };
      const response = await API.get("/categories", config);
      return response.data;
    } catch (error) {
      throw new Error("Failed to load categories");
    }
  }
);

const categoriesSlice = createSlice({
  name: "categories",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchCategories.pending, (state) => {
        state.fetchLoading = true;
      })
      .addCase(fetchCategories.fulfilled, (state, action) => {
        state.fetchLoading = false;
        state.data = action.payload;
        state.fetchError = null;
      })
      .addCase(fetchCategories.rejected, (state, action) => {
        state.fetchLoading = false;
        state.fetchError = action.error.message;
        state.data = null;
        state.message = null;
      });
  },
});

export default categoriesSlice.reducer;
