import React from 'react';
import { Link } from 'react-router-dom';
import Navbar from '../../navbar';
import Header from '../../header';

const FormStep1 = () => {
    return (
        <div>
            <Navbar />
            <Header />
            <div className='wrapper'>

                <div className='AppFormWrapper'>
                    <div className='AppFormHeader'>
                        <h2>Application for Renewal: Gaming Licence</h2>
                        <h6>Application No. <b>GBB 1.a.3</b></h6>
                        <div className='stepBar'>
                            <div className='step step01 current'>
                                <div className='dot'></div>
                                <h4>
                                    <b>In Progress</b>
                                    <span>This is a description.</span>
                                </h4>
                            </div>
                            <div className='step step02'>
                                <div className='dot'></div>
                                <h4>
                                    <b>Waiting</b>
                                    <span>This is a description.</span>
                                </h4>
                            </div>
                            <div className='step step03'>
                                <div className='dot'></div>
                                <h4>
                                    <b>Waiting</b>
                                    <span>This is a description.</span>
                                </h4>
                            </div>
                            <div className='step step04'>
                                <div className='dot'></div>
                                <h4>
                                    <b>Waiting</b>
                                    <span>This is a description.</span>
                                </h4>
                            </div>
                            <div className='step step05'>
                                <div className='dot'></div>
                                <h4>
                                    <b>Waiting</b>
                                    <span>This is a description.</span>
                                </h4>
                            </div>
                            <div className='step step06'>
                                <div className='dot'></div>
                                <h4>
                                    <b>Waiting</b>
                                    <span>This is a description.</span>
                                </h4>
                            </div>
                        </div>
                    </div>
                    <div className='AppFormContent'>
                        <div className='applicantText'>
                            An applicant for a licence or renewal has no right to be awarded a licence. All licences issued by the gaming board for the bahamas are contingent on the ongoing suitability for licensing of the persons to whom they are issued. In every case, the applicant bears the onus of proving full compliance with all applicable qualification criteria.
                        </div>
                        <h5>A Gaming Licence is required for:</h5>   
                        <ul>
                            <li>The operation of a casino and the playing in or on designated areas of the casino resort, or such various separate parts of such premises as are specified in the licence, including private gaming areas approved by the Board in the prescribed manner, of any gambling game, including, but not limited to —
                                <ol>
                                    <li>Gambling games played on traditional or electronic gaming tables and slot machines;</li>
                                    <li>Any gaming device, operated for any consideration, for the play of poker, blackjack, any other card or table game, or keno or any simulation or variation of any of the foregoing, including, but not limited to, any game in which numerals, numbers, or any pictures, representations, or symbols  are used as an equivalent or substitute for cards in the conduct of such game;</li>
                                </ol>
                            </li>
                            <li>the placing and acceptance of bets on any event;</li>
                            <li>the conduct of pari-mutuel wagering through a hub; and</li>
                            <li>the arrangement, organization and operation of junkets.</li>
                        </ul>
                        <h4>APPLICANT FOR THE RENEWAL OF A GAMING LICENCE <span className='red'>(LIMITED)</span>:</h4>
                        <div className='fieldRow'>
                            <div className='fieldWrap'>
                                <label>Registered name of business</label>
                                <input type='text' placeholder='Marvin McKinney' />
                            </div>
                            <div className='fieldWrap'>
                                <label>Trading name of business </label>
                                <input type='text' placeholder='Marvin McKinney' />
                            </div>
                            <div className='fieldWrap'>
                                <label>Date of completion of form</label>
                                <input type='text' placeholder='November 16, 2022' />
                            </div>
                        </div>
                    </div>
                    <div className='AppFormFooter'>
                        <div className='appFootLeft'>
                            <button type='submit' className='reset'>Clear Form</button>
                        </div>
                        <div className='appFootRight'>
                            {/* <button type='submit' className='submit'>Next</button> */}
                            <Link to={'/form-step-02'} className='submit'>Next</Link>
                        </div>
                    </div>
                </div>


            </div>
        </div>
    )
}
export default FormStep1