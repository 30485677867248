// reducers/counterSlice.js
import { createSlice } from '@reduxjs/toolkit';

const counterSlice = createSlice({
  name: 'counter',
  initialState: {
    value: 0,
    formDropdown: true,
    applicationDropdown: true,
    activeItem: 'Dashboard',
    selectedControl: 'Controls',
    applyingFormData:{
      form:'',
      id:null
    }
  },
  reducers: {
    toggleFormDropdown: (state) => {
      state.formDropdown = !state.formDropdown
    },
    toggleApplicationDropdown: (state) => {
      state.applicationDropdown = !state.applicationDropdown
    },
    increment: (state) => {
      state.value += 1;
    },
    decrement: (state) => {
      state.value -= 1;
    },
    setActiveItem: (state, action) => {
      state.activeItem = action.payload
    },
    setSelectedControl: (state, action) => {
      state.selectedControl = action.payload
    },
    setApplyingForm:(state,action) => {
      state.applyingFormData = {
        form:action.payload.form,
        id:action.payload.id,
        name:action.payload.name,
        category:action.payload.category
      }
    }
  },
});

export const { increment, decrement, toggleApplicationDropdown, toggleFormDropdown, setActiveItem, setSelectedControl,setApplyingForm } = counterSlice.actions;
export default counterSlice.reducer;
