import React, { useEffect, useState } from 'react';
import iconFolder from "../../../assets/media/icon-folder.svg";
import iconMenuDots from "../../../assets/media/icon-menu-dots.svg";
import iconUpload from "../../../assets/media/icon-upload.svg";
import iconUpload2 from "../../../assets/media/icon-upload-white.svg";
import imageUploaded from "../../../assets/media/image-uploaded.png";
import { Link } from 'react-router-dom';
import Navbar from '../../navbar';
import Header from '../../header';
import { useDispatch, useSelector } from 'react-redux';
import { createcabinet, fetchcabinets } from '../../../store/reducers/cabinetSlice';
import ReactModal from 'react-modal';

const FileCabinet = () => {
    const [selectedFile, setSelectedFile] = useState(null);
    const [selectedFolder, setSelectedFolder] = useState(null);
    const cabinets = useSelector((state) => state.cabinets.cabinets);
    const dispatch = useDispatch();
    const user = useSelector((state) => state.auth.user);
    const [showModal, setShowModal] = useState(false);
    const [folderName , setFolderName] = useState('')

    const getdatafunction = () => {
        dispatch(fetchcabinets(user.access_token));
    }
    
    useEffect(() => {
        getdatafunction()
    }, [dispatch, user]);

    const handleFileChange = (e) => {
        const imageFile = e.target.files[0];
        const reader = new FileReader();
        reader.onloadend = () => {
            setSelectedFile(imageFile);
        };
        reader.readAsDataURL(imageFile);
        uploadFile(imageFile)
    };

    const uploadFile = (file) => {
        if (!file) {
            alert('Please select a file.');
            return;
        }
    };

    const formatDate = (dateString) => {
        const options = { month: 'long', day: 'numeric', year: 'numeric' };
        const date = new Date(dateString);
        return date.toLocaleDateString('en-US', options);
    };

    const openModal = () => {
        setShowModal(true);
    };

    const closeModal = () => {
        setShowModal(false);
    };

    const submitData = ()=>{
        dispatch(createcabinet({
            folder: folderName,
            user_id: user.user.id,
            company_id: user.user.company_id,
            accessToken: user.access_token,
        }))
        getdatafunction()
        closeModal()
    }

    return (
        <div>
            <Navbar />
            <Header />
            <div className='wrapper'>
                <div className='boxAppBoard boxWhite'>
                    <div className='boardHeader noMg'>
                        <div className='boardHeadLeft'>
                            <h2>File Cabinet</h2>
                        </div>
                        <div className='boardHeadRight'>
                            <div className='boardFilter'>
                                <div className="form-check form-check-inline">
                                    <input className="form-check-input" type="checkbox" value="All" checked />
                                    <label className="form-check-label">All</label>
                                </div>
                                <div className="form-check form-check-inline">
                                    <input className="form-check-input" type="checkbox" value="Folders" />
                                    <label className="form-check-label">Folders</label>
                                </div>
                                <div className="form-check form-check-inline">
                                    <input className="form-check-input" type="checkbox" value="Attachments" />
                                    <label className="form-check-label">Attachments</label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='divFiles'>
                    <div className='headFiles'>
                        <div className='leftFiles'>
                            <h3 className='noMg'>Folders</h3>
                        </div>
                        <div className='rightFiles'>
                            <Link className='addButton'onClick={openModal} >
                                <img src={iconUpload2} alt="" />
                                <span >Create Folder</span>
                            </Link>
                        </div>
                    </div>
                    <div className='rowFolders'>
                        {cabinets.map((item) => (
                            <div onClick={()=>{setSelectedFolder(item.folder)}} className='colFolder'>
                                <div className='foldWrap'>
                                    <Link className='foldNav' ><img src={iconMenuDots} alt='' /></Link>
                                    <Link className='foldLink' ><img src={iconFolder} alt="" /></Link>
                                    <h6><Link >{item.folder}</Link></h6>
                                    <p>{formatDate(item.created_at)}</p>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
                <div className='divFiles'>
                    <div className='headFiles'>
                        <div className='leftFiles'>
                            <h3 className='noMg'>Attachments</h3>
                        </div>
                        <div className='rightFiles'>
                        </div>
                    </div>
                    <div className='rowAttachments'>
                        <div className='colAttachment upload'>
                            <label htmlFor="imageUpload">
                                <img src={iconUpload}  alt="" />
                                <span>Upload File</span>
                            </label>
                            <input
                                type="file"
                                id="imageUpload"
                                style={{ display: 'none' }}
                                onChange={handleFileChange}
                            />
                        </div>
                        {cabinets.map((item) => {
                    if (item.folder === selectedFolder) {
                        return (
                            <div className='colAttachment uploaded'>
                                <img src={item.s3_path} alt="" />
                            </div>
                        );
                    }
                })}
                    </div>
                </div>
            </div>

            <ReactModal isOpen={showModal} onRequestClose={closeModal}>
                <div style={{ position: 'absolute', top: 0, left: 100, width: '100%', height: '100%', backgroundColor: '#CCCCCC', zIndex: 999 }}>
                            <div style={{ backgroundColor: '#fff', width: '50%', margin: 'auto', marginTop: '10%', padding: '20px', borderRadius: '5px' }}>
                                <h1 style={{ marginBottom: '20px' }}>Create Folder</h1>
                                <div className="boardHeadRight">
                                    <div>
                                    <input
                                    onChange={(e)=>{setFolderName(e.target.value)}}
                                       style={{ width: '100%',border:'1px solid #CCCCCC' }}
                                        placeholder='Folder Name'
                                        name='folderName'
                                    />
                                    </div>
                                </div>
                                <div style={{ textAlign: 'right', marginTop: '20px' }}>
                                    <button  onClick={closeModal}  style={{ marginRight: '10px', padding: '5px 10px', backgroundColor: '#ccc', border: 'none', borderRadius: '3px', cursor: 'pointer' }} >Close</button>
                                    <button onClick={()=>{submitData()}} style={{ padding: '5px 10px', backgroundColor: '#007bff', color: '#fff', border: 'none', borderRadius: '3px', cursor: 'pointer' }}>Submit</button>
                                </div>
                            </div>
                        </div>
            </ReactModal>
        </div>
    );
};

export default FileCabinet;
