// AppApplications
import React, { Fragment, useEffect, useState } from "react";
import iconFilePdf from "../../../assets/media/icon-file-pdf.svg";
import iconFileImage from "../../../assets/media/icon-file-image.svg";
import iconFileDoc from "../../../assets/media/icon-file-doc.svg";
import iconFileDownload from "../../../assets/media/icon-file-download.svg";
import iconSearch from "../../../assets/media/icon-search.svg";

import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { fetchApplications } from "../../../store/reducers/applicationSlice";
import Navbar from "../../navbar";
import Header from "../../header";
import Pagination from "../dashboard/appPagination";

export const formatDate = (inputDate) => {
  const date = new Date(inputDate);
  const options = { year: "numeric", month: "long", day: "2-digit" };
  return date.toLocaleDateString(undefined, options);
};

function lastFiveCharactersMatch(str1, str2) {
  const lastFiveStr1 = str1.substr(-5); // Extract last 5 characters of str1
  const lastFiveStr2 = str2.substr(-5); // Extract last 5 characters of str2
  return lastFiveStr1 === lastFiveStr2;
}

function capitalizeWords(str) {
  return str.replace(/\b\w/g, (char) => char.toUpperCase());
}

const AppApplications = () => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth.user);
  const authToken = user.access_token;

  const applications = useSelector((state) => state.applications);
  const activeItem = useSelector((state) => state.counter.activeItem);

  const [currentPage, setCurrentPage] = useState(1);
  useEffect(() => {
    dispatch(fetchApplications({ accessToken: authToken }));
  }, [authToken, dispatch]);

  const filtered = () => {
    const filteredData = applications?.data.map((item, i) => {
      if (lastFiveCharactersMatch(item.state, activeItem)) {
        return {
          ...item,
        };
      }
    });
    return filteredData?.filter((el) => el !== undefined);
  };
  return (
    <div>
      <Navbar />
      <Header />
      <div className="wrapper">
        <div className="boxAppBoard boxWhite">
          <div className="boardHeader">
            <div className="boardHeadLeft">
              <h2>{activeItem} Applications</h2>
            </div>
            <div className="boardHeadRight">
              <div className="searchForm">
                <input type="text" placeholder="input search text" />
                <button>
                  <img src={iconSearch} alt="" />
                </button>
              </div>
              <div className="datePicker"></div>
            </div>
          </div>
          <div className="boardContent">
            <div className="boardThead">
              <div className="boardCol w-85">S.NO.</div>
              <div className="boardCol w-165">Application No.</div>
              <div className="boardCol w-adj3">Application Name</div>
              <div className="boardCol w-165">Submitted Date</div>
              <div className="boardCol w-165">Attachments</div>
              <div className="boardCol w-110"></div>
            </div>
            {filtered().map((item, index) => (
              <Fragment key={index}>
                <div className={`boardTrow ${(currentPage === 1 ? currentPage : ((currentPage - 1) * 10) + 1) <= index + 1 && index < (currentPage === 1 ? 10 : (currentPage * 10)) ? '' : 'd-none'} `}>
                  <div className="boardCol w-85">{index + 1}</div>
                  <div className="boardCol w-165">{"item.form_id"}</div>
                  <div className="boardCol w-adj3">
                    <div className="boardTitle">
                      <strong>{item.name}</strong>
                      <span>{capitalizeWords(item.form)}</span>
                    </div>
                  </div>
                  <div className="boardCol w-165">
                    {formatDate(item.updated_at)}
                  </div>
                  <div className="boardCol w-165">
                    <div className="boardDownload">
                      <Link to={"/"}>
                        <img src={iconFilePdf} alt="" />
                      </Link>
                      <Link to={"/"}>
                        <img src={iconFileDoc} alt="" />
                      </Link>
                      <Link to={"/"}>
                        <img src={iconFileImage} alt="" />
                      </Link>
                    </div>
                  </div>
                  <div className="boardCol w-110">
                    <div className="boardActions">
                      <Link to={"/"}>
                        <img src={iconFileDownload} alt="" />
                      </Link>
                    </div>
                  </div>
                </div>
              </Fragment>
            ))}
          </div>
          <Pagination
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            totalPages={parseInt(applications?.data?.length % 10) + 1}
          />
        </div>
      </div>
    </div>
  );
};
export default AppApplications;
