import React, { useEffect } from "react";
import logo from "../../assets/media/logo.png";
import iconAppForm from "../../assets/media/icon-app-form.svg";
import iconApp from "../../assets/media/icon-application.svg";
import iconCertification from "../../assets/media/icon-certification.svg";
import iconDashboard from "../../assets/media/icon-dashboard.svg";
import iconDiscrepancies from "../../assets/media/icon-discrepancies.svg";
import iconDraft from "../../assets/media/icon-draft.svg";
import iconQuota from "../../assets/media/icon-quota.svg";
import iconRenewel from "../../assets/media/icon-renewel.svg";
import iconFeedback from "../../assets/media/icon-feedback.svg";
import iconEmployees from "../../assets/media/icon-employees.svg";
import iconLogout from "../../assets/media/icon-logout.svg";
import iconArrowUp from "../../assets/media/icon-arrow-up.svg";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { logoutUser } from "../../store/reducers/authSlice";
import { fetchCategories } from "../../store/reducers/categoriesSlice";
import {
  setActiveItem,
  toggleApplicationDropdown,
  toggleFormDropdown,
} from "../../store/reducers/counterSlice";

const Navbar = () => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth.user);
  const authToken = user.access_token;

  const formDropdown = useSelector((state) => state.counter.formDropdown);
  const applicationFormDropDown = useSelector(
    (state) => state.counter.applicationDropdown
  );

  const activeItem = useSelector((state) => state.counter.activeItem);

  const categories = useSelector((state) => state.categories);

  useEffect(() => {
    dispatch(fetchCategories({ accessToken: authToken }));
  }, [authToken, dispatch]);
  const location = useLocation();
  const navigate = useNavigate();
  console.log(user, "userData");

  return (
    <nav className="navbar">
      <div className="navHead">
        <Link to={"/"}>
          <img src={logo} className="logo" alt="" />
        </Link>
        <div style={{ height: "calc(100vh - 100px)", overflowY: "auto" }}>
          <ul>
            <li
              className={`${
                location.pathname === "/dashboard" ? "active" : ""
              }`}
            >
              <Link to={"/dashboard"}>
                <img src={iconDashboard} alt="" />
                <span>Dashboard</span>
              </Link>
            </li>
            {/* <li className={`${activeItem === 'Create Forms' ? 'active' : ''}`}><Link onClick={() => {
                        dispatch(setActiveItem('Create Forms'))
                    }} to={'/create-forms'}><img src={iconCertification} alt='' /><span>Create Forms</span></Link></li> */}
            <li className={`collapsed`}>
              <div
                className="navBlock "
                style={{ cursor: "pointer" }}
                onClick={() => {
                  dispatch(toggleFormDropdown());
                }}
              >
                <a
                  to={"/forms"}
                  onClick={() => {
                    // dispatch(setActiveItem(categories?.data[0].name));
                    navigate(`/forms?item=1`, {
                      state: {
                        category: "Gaming License & Tax",
                      },
                    });
                  }}
                >
                  <img src={iconAppForm} alt="" />
                  <span>Application Forms {formDropdown}</span>
                </a>
                <img className="iconToggle" src={iconArrowUp} alt="" />
              </div>
              <ul
                style={{
                  display: formDropdown ? "none" : "",
                  cursor: "pointer",
                }}
              >
                {categories?.data?.map((item, index) => (
                  <li
                    key={index}
                    className={`${
                      +location.search.split("=")[1] === item.id &&
                      location.pathname.includes("forms")
                        ? "active"
                        : ""
                    }`}
                  >
                    <a
                      onClick={() => {
                        // dispatch(setActiveItem(item.name));
                        navigate(`/forms?item=${item.id}`, {
                          state: {
                            category: item.name,
                          },
                        });
                      }}
                    >
                      <span>{item.name}</span>
                    </a>
                  </li>
                ))}
              </ul>
            </li>
            <li className={`collapsed`}>
              <div
                className="navBlock"
                style={{ cursor: "pointer" }}
                onClick={() => {
                  dispatch(toggleApplicationDropdown());
                }}
              >
                <Link
                  to={"/in-process-applications"}
                  onClick={() => {
                    dispatch(setActiveItem("In Process"));
                  }}
                >
                  <img src={iconApp} alt="" />
                  <span>My Applications</span>
                </Link>
                <img className="iconToggle" src={iconArrowUp} alt="" />
              </div>
              <ul style={{ display: applicationFormDropDown ? "none" : "" }}>
                <li
                  className={`${
                    location.pathname === "/in-process-applications"
                      ? "active"
                      : ""
                  }`}
                >
                  <Link
                    to={"/in-process-applications"}
                    onClick={() => {
                      // dispatch(setActiveItem('In Process'));
                    }}
                  >
                    <span>In Process</span>
                  </Link>
                </li>
                <li
                  className={`${
                    location.pathname === "/pending-applications"
                      ? "active"
                      : ""
                  }`}
                >
                  <Link
                    to={"/pending-applications"}
                    onClick={() => {
                      dispatch(setActiveItem("Pending"));
                    }}
                  >
                    <span>Pending</span>
                  </Link>
                </li>
                <li
                  className={`${
                    location.pathname === "/rejected-applications"
                      ? "active"
                      : ""
                  }`}
                >
                  <Link
                    to={"/rejected-applications"}
                    onClick={() => {
                      dispatch(setActiveItem("Rejected"));
                    }}
                  >
                    <span>Rejected</span>
                  </Link>
                </li>
                <li
                  className={`${
                    location.pathname === "/approved-applications"
                      ? "active"
                      : ""
                  }`}
                >
                  <Link
                    to={"/approved-applications"}
                    onClick={() => {
                      dispatch(setActiveItem("Approved"));
                    }}
                  >
                    <span>Approved</span>
                  </Link>
                </li>
              </ul>
            </li>
            <li
              className={`${location.pathname === "/renewals" ? "active" : ""}`}
            >
              <Link
                to={"/renewals"}
                onClick={() => {
                  dispatch(setActiveItem("Renewal"));
                }}
              >
                <img src={iconRenewel} alt="" />
                <span>Renewal</span>
              </Link>
            </li>
            <li
              className={`${
                location.pathname === "/discrepancies" ? "active" : ""
              }`}
            >
              <Link
                to={"/discrepancies"}
                onClick={() => {
                  dispatch(setActiveItem("Discrepancies"));
                }}
              >
                <img src={iconDiscrepancies} alt="" />
                <span>Discrepancies</span>
              </Link>
            </li>
            <li
              className={`${
                location.pathname === "/certifications" ? "active" : ""
              }`}
            >
              <Link
                onClick={() => {
                  dispatch(setActiveItem("Certifications"));
                }}
                to={"/certifications"}
              >
                <img src={iconCertification} alt="" />
                <span>Certifications</span>
              </Link>
            </li>
            <li
              className={`${
                location.pathname === "/file-cabinet" ? "active" : ""
              }`}
            >
              <Link
                onClick={() => {
                  dispatch(setActiveItem("Cabinet"));
                }}
                to={"/file-cabinet"}
              >
                <img src={iconQuota} alt="" />
                <span>File Cabinet</span>
              </Link>
            </li>
            <li className={`${location.pathname === "/draft" ? "active" : ""}`}>
              <Link
                onClick={() => {
                  dispatch(setActiveItem("Draft"));
                }}
                to={"/draft"}
              >
                <img src={iconDraft} alt="" />
                <span>Draft</span>
              </Link>
            </li>
            {user.user?.user_role === "company_admin" && (
              <li
                className={`${
                  location.pathname === "/employees" ? "active" : ""
                }`}
              >
                <Link
                  onClick={() => {
                    dispatch(setActiveItem("Employees"));
                  }}
                  to={"/employees"}
                >
                  <img src={iconEmployees} alt="" />
                  <span>Employees</span>
                </Link>
              </li>
            )}
            <li
              className={`${location.pathname === "/feedback" ? "active" : ""}`}
            >
              <Link
                onClick={() => {
                  dispatch(setActiveItem("GB"));
                }}
                to={"/feedback"}
              >
                <img src={iconFeedback} alt="" />
                <span>Feedback To GB</span>
              </Link>
            </li>
          </ul>
          <div
            style={{ display: "flex", flexDirection: "row", cursor: "pointer" }}
            className="navFoot"
            onClick={() => {
              dispatch(logoutUser());
            }}
          >
            <img className="" src={iconLogout} alt="" />
            <p className="mx-2 mt-3">Logout</p>
          </div>
        </div>
      </div>
    </nav>
  );
};
export default Navbar;
