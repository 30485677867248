import React from 'react';
import { IoChevronBackSharp, IoChevronForwardSharp } from "react-icons/io5"

const FormHeader = (props) => {
    const handleGoPrev = (event) => {
        //change page
        console.log("Go previous");
        props.previousPage(event);

    }
    const handleGoForw = (event) => {
        //change page
        props.nextPage(event);
        console.log("Go Forward");
    }

    return (
        <>
            <div className='AppFormBar noShadow'>
                <div className='title'>
                    <input
                        value={props.applicationName}
                        style={{
                            width: "400px",
                            background: "#323639",
                            border: "solid 0px #ccc",
                            borderRadius: "5px",
                            padding: "5px",
                            fontWeight: "600",
                            fontSize: "16px",
                            lineHeight: "22px",
                            color: "#fff",
                        }} />
                </div>
                <button
                    type='submit'
                    style={{
                        backgroundColor: '#114df2',
                        color: 'white',
                        padding: '5px',
                        float: 'right',
                        border: '1px solid #114df2',
                        borderRadius: 5,
                        width: '15%',
                        height: '15%'

                    }}
                    onClick={(() => props.saveToDrafts("drafts"))}
                >Save to Draft</button>

                <button
                    type='submit'
                    style={{
                        backgroundColor: '#114df2',
                        color: 'white',
                        padding: '5px',
                        float: 'right',
                        border: '1px solid #114df2',
                        borderRadius: 5,
                        width: '15%',
                        height: '15%'

                    }}
                    onClick={(props.handleSubmitWithLoading)}
                // disabled={isLoading}
                >

                    Submit
                </button>
                <div className='pagination'>
                    <div className='pageNum d-flex align-items-center'>
                        <IoChevronBackSharp onClick={handleGoPrev} />

                        {/* Replace span with an input */}
                        <input
                            type="number"
                            value={props.currentPage}
                            onChange={(e) => props.handlePageChange(Number(e.target.value))}
                            style={{
                                width: '50px',
                                height: '50px',
                                textAlign: 'center',
                                color: '#fff',
                                backgroundColor: '#323639',
                                border: '1px solid #ccc',
                                borderRadius: '5px',
                            }}
                            min="1"
                            max={Number(props.totalPages)}
                        />

                        <span className='slash'>{`/`}<span style={{ marginLeft: 10 }}></span></span>{props.totalPages}
                        <IoChevronForwardSharp onClick={handleGoForw} />
                    </div>
                </div>
            </div>
        </>
    )
}

export default FormHeader;