import React,{useState,useEffect} from "react";
import {formComponentMap} from "../../../utility/utility"
const FormLoader = ({applicationName,setFormIds,setPageIds,setIsLoaded}) =>{
    const [formHtml, setFormHtml] = useState(''); // State to hold the HTML content
    const [isLoading, setIsLoading] = useState(true); // Loading state

    useEffect(() => {
      if(applicationName){
        setIsLoading(true);
        fetch('/'+formComponentMap[applicationName]) // Assumes form.html is in the public folder
        .then((response) => response.text())
        .then((html) => {
          setFormHtml(html); // Store the loaded HTML in state
          setIsLoaded(true);
        })
        .catch((error) => {
          console.error('Error loading form:', error);
        })
        .finally(() => {
          setIsLoading(false);
      });
      }
      }, [applicationName]);

      useEffect(() => {
        const formIdElement = document.querySelectorAll('[id^="form"]');
        const formIds = Array.from(formIdElement)
          .filter(formIdElement => /^form\d+_\d+$/.test(formIdElement.id))
          .map(formIdElement => formIdElement.id);
        const pageIdElement = document.querySelectorAll('[id^="page"]');
        const pageIds = Array.from(pageIdElement)
          .filter(pageIdElement => /^page\d+$/.test(pageIdElement.id))
          .map(pageIdElement => pageIdElement.id);
        
        setFormIds(formIds);
        setPageIds(pageIds)
        console.log(pageIds);
        console.log(formIds);
        }, [formHtml]);

        if (isLoading) {
          return <div className="spinner"></div>; // Display the spinner while loading
      }

    return (
        <div id = "DocumentPDF"
        dangerouslySetInnerHTML={{ __html: formHtml }}>
        </div>
    )
}

export default FormLoader