import Avatar01 from "../../assets/media/avatar-01.png";
import classes from "./style.module.css"
function Modal({ show, handleClose, colWidth, children }) {
    const showHideClassName = show ? "modal display-block" : "modal display-none";

    return (
        <>
            {show && <div onClick={handleClose} className={classes['modal-backdrop']}>
                <div className={[`col-12`, `col-md-${colWidth ? colWidth : 12}`, "p-4", classes['modal-content-wrapper']].join(" ")}>
                    {
                        children
                    }
                </div>
            </div>}
        </>
    );
}

export default Modal