import React, { useEffect } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { loginUser } from '../../../store/reducers/authSlice';
import logo from "../../../assets/media/logo.png";
import booksAnimation from "../../../assets/media/books-animation.gif";
import iconMark from "../../../assets/media/icon-mark.png";
import iconHide from "../../../assets/media/icon-hide.png";
import GBInput from '../../input';
import { createNotification } from '../../../store/reducers/notificationSlice';

const Login = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const user = useSelector((state) => state.auth.user);

    const {
        handleChange,
        handleBlur,
        handleSubmit,
        values,
        touched,
        errors,
    } = useFormik({
        initialValues: {
            email: '',
            password: '',
        },
        validationSchema: Yup.object({
            email: Yup.string().email('Invalid email address').required('Email is required'),
            password: Yup.string().required('Password is required'),
        }),
        onSubmit: (formValues) => {
            dispatch(loginUser({ emailOrPhone: formValues.email, password: formValues.password }));
            
        },
    });

    useEffect(() => {
        if (user?.access_token) {
            navigate('/dashboard');
        }
    }, [navigate, user]);

    const renderInputField = (name, label, type, iconSrc, placeholder) => (
        <div className={`fieldWrap ${touched[name] && errors[name] ? 'error-message' : ''}`}>
            <GBInput
                label={label}
                type={type}
                iconSrc={type !== 'password' && iconSrc}
                placeholder={placeholder}
                id={name}
                name={name}
                setValue={handleChange}
                onBlur={handleBlur}
                value={values[name]}
            />
            {touched[name] && errors[name] && <div className="error-message">{errors[name]}</div>}
        </div>
    );

    return (
        <div className="App">
            <div className="container">
                <div className='landingWrapper'>
                    <div className='landingRow'>
                        <div className='landingCol'>
                            <div className='fieldsWrapper'>
                                <img src={logo} className="logo" alt="logo" />
                                <h2>Signin With Us</h2>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor</p>
                                <form className='gb-login-form' onSubmit={handleSubmit}>
                                    {renderInputField('email', 'Email Address', 'email', iconMark, 'Email Address')}
                                    {renderInputField('password', 'Password', 'password', iconHide, '********')}
                                    <div className='gb-field-wrap'>
                                        <Link to={'/forget-password'} className='forget-link'>Forgot Password?</Link>
                                    </div>
                                    <div className='gb-form-footer'>
                                        <button type="submit" className='form-submit'>Sign In</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                        <div className='landingCol'>
                            <div className='imageWrapper'>
                                <img src={booksAnimation} className="booksAnimation" alt="" />
                                <h3><span>Paper Less</span><strong>Documentations</strong></h3>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Login;
