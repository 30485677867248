import React, { useEffect, useState } from 'react';
import iconSearch from "../../../assets/media/icon-search.svg";
import Navbar from '../../navbar';
import Header from '../../header';
import { useDispatch, useSelector } from 'react-redux';
import userImagePlaceholder from "../../../assets/media/avatar2.jpg";
import { deleteNotification, fetchNotifications, updateNotification } from '../../../store/reducers/notificationSlice';
import { getallUser } from '../../../store/reducers/userSlice';
import Pagination from '../dashboard/appPagination';
import iconFileDelete from "../../../assets/media/icon-file-delete.svg";
import { Link } from 'react-router-dom';
import { showSuccess } from '../../../helpers/toast';
import Modal from '../../modal/Modal';

const Notifications = () => {
    const dispatch = useDispatch();
    const [currentPage, setCurrentPage] = useState(1);
    const user = useSelector((state) => state.auth.user);
    const notifications = useSelector((state) => state.notifications.notifications);
    const [searchTerm, setSearchTerm] = useState("");
    const getUserall = useSelector((state) => state.usersdata.getalluserdata);
    const [selectedNotifications, setSelectedNotifications] = useState([]);
    const [selectAll, setSelectAll] = useState(false);
    const [successMessageShown, setSuccessMessageShown] = useState(false);
    const [modalVisible, setModalVisible] = useState(false);
    const [selectedNotificationData, setSelectedNotificationData] = useState(null);

    const handleNotificationSelected = (notificationId) => {
        const selectedNotification = notifications.find(notification => notification.id === notificationId);
        setSelectedNotificationData(selectedNotification);
        if (selectedNotification && !selectedNotification.user_read_status) {
            const updatePayload = {
                notificationId: selectedNotification.id,
                message: selectedNotification.message, 
                user_read_status: true, 
                accessToken: user.access_token, 
            };
            dispatch(updateNotification(updatePayload));
        }
        setModalVisible(true);
    };

    const getdatafunction = () => {
        dispatch(getallUser(user.access_token));
        dispatch(fetchNotifications(user.access_token));
    }
    const close = () => {
        setSelectedNotificationData(null)
        setModalVisible(false)
        getdatafunction()
    }
    useEffect(() => {
        getdatafunction()
    }, [dispatch, user]);

    const handleInputChange = event => setSearchTerm(event.target.value);

    const getUserData = (userId) => {
        const userData = getUserall.find(user => user.id === userId);
        return userData || { full_name: 'Unknown', image: userImagePlaceholder };
    };

    const getNotificationTime = (timestamp) => {
        const notificationDate = new Date(timestamp);
        const today = new Date();

        if (
            notificationDate.getDate() === today.getDate() &&
            notificationDate.getMonth() === today.getMonth() &&
            notificationDate.getFullYear() === today.getFullYear()
        ) {
            return notificationDate.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
        } else {
            return notificationDate.toLocaleDateString();
        }
    };

    const handleNotificationSelect = (notificationId) => {
        const index = selectedNotifications.indexOf(notificationId);
        if (index === -1) {
            setSelectedNotifications([...selectedNotifications, notificationId]);
        } else {
            setSelectedNotifications(selectedNotifications.filter(id => id !== notificationId));
        }
    };

    const handleSelectAll = () => {
        if (selectAll) {
            setSelectedNotifications([]);
        } else {
            setSelectedNotifications(notifications.map(notification => notification.id));
        }
        setSelectAll(!selectAll);
    };

    const getTotalPages = () => {
        if (Number.isInteger(notifications.length / 10)) return notifications.length / 10;
        return Math.floor(notifications.length / 10) + 1
    }

    const filteredNotifications = notifications
    .filter(notification =>{
     return  notification.is_admin != true && notification.message.toLowerCase().includes(searchTerm.toLowerCase())
    }
    ).sort((a, b) => b.id - a.id);

    const deleteSelectedNotifications = () => {
        selectedNotifications.forEach(notificationId => {
            const config = {
                accessToken: user.access_token,
                notification_ids: notificationId
            };
            dispatch(deleteNotification({ configdata: config }));
        });
        setSelectedNotifications([]);
        setSelectAll(false);
        if (!successMessageShown) {
            showSuccess('Notifications deleted successfully');
            setSuccessMessageShown(true);
        }
        getdatafunction();
    };

    

    return (
        <div>
            <Navbar />
            <Header />
            <div className='wrapper'>
                <div className='boxAppBoard boxWhite'>
                    <div className='boardHeader'>
                        <div className='boardHeadLeft'>
                            <h2>Notifications</h2>
                        </div>
                        <div className='boardHeadRight'>
                            <div className='searchForm'>
                                <input type='text' placeholder='Search notifications' onChange={handleInputChange} value={searchTerm} />
                                <button><img src={iconSearch} alt='Search' /></button>
                            </div>
                        </div>
                    </div>
                    {filteredNotifications.length != 0 ? 
                    
                    <table >
                        <tr style={{ borderBottom: '1px dashed black'}}>
                            <th  >
                                <input
                                    className='mx-2' style={{ height: 18 }}
                                    type='checkbox'
                                    checked={selectAll}
                                    onChange={handleSelectAll}
                                />
                            </th>
                            <th style={{float:'right',width:'100%'}}>
                                <Link onClick={deleteSelectedNotifications} style={{ float: 'right', marginRight: 10, display: 'flex', flexDirection: 'row' }}>
                                    <img src={iconFileDelete} alt='' style={{ height: 20 }} /><p className=''></p>
                                </Link>
                            </th>
                        </tr>
                        {filteredNotifications.map((notification,index) => (
                            <>
                                <tr key={notification.id} className={`boardnotification ${(currentPage === 1 ? currentPage : ((currentPage - 1) * 10) + 1) <= index + 1 && index < (currentPage === 1 ? 10 : (currentPage * 10)) ? '' : 'd-none'} `}>
                                    <td style={{ backgroundColor: notification.user_read_status === false ? '#C7C5C2' : 'white' }}>
                                        <input className='mx-2' style={{ height: 18 }}
                                            type='checkbox'
                                            checked={selectedNotifications.includes(notification.id)}
                                            onChange={() => handleNotificationSelect(notification.id)}
                                        />
                                    </td>
                                    <td onClick={() => handleNotificationSelected(notification.id)} style={{ width: '100%', backgroundColor: notification.user_read_status === false ? '#C7C5C2' : 'white',cursor:'pointer' }}>
                                        <div key={notification.id} style={{}}>

                                            <div className='divnotification'>

                                                <div className='uploadLeft'>

                                                    <img
                                                        style={{
                                                            width: '30px',
                                                            height: '30px',
                                                            borderRadius: '50%',
                                                            marginRight: '10px',
                                                        }}
                                                        className='avatar'
                                                        src={getUserData(notification.user_id).image || userImagePlaceholder}
                                                        alt=""
                                                    />
                                                    <p><strong>{getUserData(notification.user_id).full_name}</strong></p>

                                                </div>

                                                <div className='uploadRight'>
                                                    {/* <p style={{ textAlign: 'center' }}> {notification.message}</p> */}
                                                </div>
                                                <div className='uploadRight'>
                                                    <div style={{ marginLeft: 'auto' }}>{getNotificationTime(notification.timestamp)}</div>
                                                </div>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            </>

                        ))}
                    </table>
                    :<h2 className='text-danger text-center my-4 py-4'>Notifications not Found</h2>
                    }
                    <Pagination
                        currentPage={currentPage}
                        setCurrentPage={setCurrentPage}
                        totalPages={getTotalPages}
                    />
                </div>
            </div>
            {modalVisible && selectedNotificationData && (
                <Modal  show={modalVisible} handleClose={() => setModalVisible(false)} colWidth={6}>
                   <div>
  <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
    <img
      style={{
        width: '30px',
        height: '30px',
        borderRadius: '50%',
        marginRight: '10px',
      }}
      className='avatar'
      src={getUserData(selectedNotificationData.user_id).image || userImagePlaceholder}
      alt=""
    />
    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', flex: 1 }}>
      <strong>{getUserData(selectedNotificationData.user_id).full_name}</strong>
      <span>{getNotificationTime(selectedNotificationData.timestamp)}</span>
    </div>
  </div>
<br/>
  <div>
    <p style={{ marginLeft: 40 }}> {selectedNotificationData.message}</p>
  </div>
  <div className='uploadRight'>
  </div>
  <div style={{ textAlign: 'right' }}>
    <button style={{ backgroundColor: 'red', color: 'white', padding: 5, borderRadius: 40, width: 100 }} onClick={close}>Close</button>
  </div>
</div>

                </Modal>
            )}
        </div>
    );
}

export default Notifications;
