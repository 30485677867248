import React, { Fragment, useEffect, useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from 'formik';
import * as Yup from 'yup';

import logo from "../../../assets/media/logo.png";
import booksAnimation from "../../../assets/media/books-animation.gif";
import iconHide from "../../../assets/media/icon-hide.png";
import iconMark from "../../../assets/media/icon-mark.png";
import GBInput from '../../input';
import { updateSignUpData } from '../../../store/reducers/signUpSlice';
import { registerUserData } from '../../../store/reducers/authSlice';

const SignUp = () => {
    const pickRandomCharacters = (inputString, count = 5) => {
        if (count <= 0 || count > inputString.length) {
            throw new Error('Invalid count parameter');
        }

        const shuffledArray = inputString.split('').sort(() => 0.5 - Math.random());
        const code = shuffledArray.slice(0, count).join('')
        return code;
    };


    const navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation();


    const queryParams = new URLSearchParams(location.search);
    const signupcode = queryParams.get('signupcode');
    const signUpUser = useSelector((state) => state.signUp);
    const user = useSelector((state) => state.auth.user);

    const [signUpCode,] = useState(pickRandomCharacters(signupcode));
    const [password, setPassword] = useState('');


    const initialValues = {
        full_name: user?.full_name || '',
        signupcode: signUpCode,
        password: password || '',
    };



    const validationSchema = Yup.object({
        full_name: Yup.string().required('Full Name is required'),
        signupcode: Yup.string().required('Sign up code is required').matches(signUpCode),
        password: Yup.string().required('Password is required').min(8, 'Password should be at least 8 characters long'),
    });

    const formik = useFormik({
        initialValues,
        validationSchema,
        onSubmit: (values) => {
            dispatch(updateSignUpData({ signupcode: signupcode, password: values.password, full_name: values.full_name }));
            if (signUpUser?.signupcode) {
                navigate('/signup-step2');
            }
        },
    });

    const { values, handleChange, handleSubmit, errors, setFieldValue } = formik;

    useEffect(() => {
        setFieldValue('full_name', user?.full_name)
    }, [user])

    const fields = [
        {
            name: 'full_name',
            label: 'Full name',
            type: 'text',
            placeholder: 'Enter your name',
            value: values.full_name,
            onChange: handleChange,
            error: errors['full_name'],
        },
        {
            name: 'signupcode',
            label: 'Signup Code',
            type: 'text',
            placeholder: 'Enter your code',
            value: values.signupcode,
            onChange: handleChange,
            error: errors['signupcode'],
            readOnly: true,
            iconSrc: iconMark
        },
        {
            name: 'password',
            label: 'Password',
            type: 'password',
            placeholder: '********',
            value: values.password,
            onChange: (e) => {
                handleChange(e);
                setPassword(e.target.value);
            },
            error: errors['password'],
            iconSrc: iconHide
        }
    ];

    useEffect(() => {
        dispatch(registerUserData({ signupcode: signupcode }))
    }, [])

    useEffect(() => {
        if (!signupcode) {
            // navigate('/');
        }
    }, [navigate, signupcode]);

    return (
        <div className="App">
            <div className="container">
                <div className="landingWrapper">
                    <div className="landingRow">
                        <div className="landingCol">
                            <div className="fieldsWrapper">
                                <img src={logo} className="logo" alt="logo" />
                                <h2>Signup With Us</h2>
                                <p>
                                    Welcome{" "}
                                    <a href={`mailto:${user?.email}`}>{user?.email}</a>
                                    <br />
                                    Please fill the form to get access for system
                                </p>
                                <form className='gb-login-form' onSubmit={handleSubmit}>
                                    {
                                        fields.map((field, index) => {
                                            return <Fragment key={index}>
                                                <GBInput
                                                    name={field.name}
                                                    type={field.type}
                                                    label={field.label}
                                                    value={field.value}
                                                    placeholder={field.placeholder}
                                                    setValue={field.onChange}
                                                    readOnly={field.readOnly}
                                                    iconSrc={field.type !== 'password' && field.iconSrc}
                                                    className={errors[`${field.name}`] ? 'error-input' : ''}
                                                />
                                                {
                                                    errors[`${field.name}`] && <div className="error-message error-message-wrap">{errors[`${field.name}`]}</div>
                                                }
                                            </Fragment>

                                        })
                                    }
                                    <div className="gb-form-footer">

                                        <button type='submit' className="form-submit">
                                            {/* <Link to={'/signup-step2'}>Continue</Link> */}
                                            Continue
                                        </button>
                                    </div>
                                </form>
                                <div className="gb-step-list">
                                    <span className="current"></span>
                                    <span></span>
                                    <span></span>
                                </div>
                            </div>
                        </div>
                        <div className="landingCol">
                            <div className="imageWrapper">
                                <div className="loginBox">
                                    <p>
                                        Already have account? <Link to={'/'} >Sign In</Link>
                                    </p>
                                </div>
                                <img src={booksAnimation} className="booksAnimation" alt="" />
                                <h3>
                                    <span>Paper Less</span>
                                    <strong>Documentations</strong>
                                </h3>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SignUp