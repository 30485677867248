import React, { useEffect } from "react";
import iconLogo from "../../../assets/media/icon-logo.png";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Navbar from "../../navbar";
import Header from "../../header";
import { useDispatch, useSelector } from "react-redux";
import {
  setActiveItem,
  setApplyingForm,
} from "../../../store/reducers/counterSlice";
import { fetchForms } from "../../../store/reducers/formSlice";

const AppFormsAdmin = () => {
  const accessToken = useSelector((state) => state.auth.user.access_token);

  const categories = useSelector((state) => state.categories);
  const activeItem = useSelector((state) => state.counter.activeItem);

  const forms = useSelector((state) => state.forms);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchForms({ accessToken: accessToken }));
  }, [accessToken, dispatch]);
  const location = useLocation();
  const navigate = useNavigate();

  const handleGotoApplyNow = (data) => {
    console.log({ data });
    dispatch(
      setApplyingForm({
        form: data?.json_data,
        id: data?.id,
        name: data?.name,
        category: data?.category,
      })
    );
    navigate(`/app-form`, {
      state: {
        category: "Gaming License & Tax",
      },
    });
  };
  return (
    <div>
      <Navbar />
      <Header />
      <div className="wrapper">
        <div className="profHeader">
          <div className="profHeadLeft">
            <h2>Application Forms</h2>
          </div>
          {/* <div className='profHeadRight'>
                        <Link className='buttonPrimary' to={'/form-step-01'}>Upload PDF Form</Link>
                    </div> */}
        </div>
        <div className="boxAppForms boxWhite">
          <ul>
            {categories?.data?.map((item, index) => (
              <li key={index}>
                <a
                  className={`${
                    +location.search.split("=")[1] === item.id ? "active" : ""
                  }`}
                  onClick={() => {
                    // dispatch(setActiveItem(item.name));
                    navigate(`/forms?item=${item.id}`, {
                      state: {
                        category: item.name,
                      },
                    });
                  }}
                >
                  {item.name}
                  <span>{item.formCount}</span>
                </a>
              </li>
            ))}
          </ul>
        </div>
        <div className="divAppForms">
          <div className="rowAppForms">
            {!forms?.fetchLoading &&
              forms?.data?.map((form, i) => {
                if (form.category === location?.state?.category) {
                  return (
                    form.is_published && (
                      <div className="colAppForm" key={i}>
                        <div className="AppFormWrap">
                          <img src={iconLogo} alt="" />
                          <h3>{form.name}</h3>
                          <p>{form.category}</p>
                          <div className="buttonGroup">
                            <a
                              className="AppFormButton"
                              to={"/app-form"}
                              onClick={() => handleGotoApplyNow(form)}
                            >
                              Appy Now
                            </a>
                            {/* <Link className='AppFormButton2' to={'/form-step-01'}>Delete Form</Link> */}
                          </div>
                        </div>
                      </div>
                    )
                  );
                }
              })}
          </div>
        </div>
      </div>
    </div>
  );
};
export default AppFormsAdmin;
