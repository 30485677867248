import { Link } from 'react-router-dom';
import { useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { forgetPassword } from '../../../store/reducers/forgetPasswordSlice';
import * as Yup from 'yup';
import logo from "../../../assets/media/logo.png";
import iconMark from "../../../assets/media/icon-mark.png";
import GBInput from '../../input';

const Forget = () => {

    const dispatch = useDispatch();

    const loading = useSelector((state) => state.forgetPassword.loading);

    const initialValues = {
        email: '',
    };

    const validationSchema = Yup.object({
        email: Yup.string().email('Invalid email').required('Email is required'),
    });


    const formik = useFormik({
        initialValues,
        validationSchema,
        onSubmit: (values) => {
            dispatch(forgetPassword({
                email: values.email
            }));
        },
    });
    const { values, handleChange, handleSubmit } = formik;

    const fields = [
        {
            name: 'email',
            label: 'Email Address',
            type: 'email',
            placeholder: 'Email Address Address',
            value: values.email,
            onChange: handleChange,
            iconSrc: iconMark
        }
    ];

    return (
        <div className="App">
            <div className="container">
                <div className='landingWrapper'>

                    <div className='lp-pwMain'>
                        <div className='lp-pwWrapper'>

                            <img src={logo} className="logo" alt="logo" />
                            <h2>Forgot Password</h2>
                            <p>Enter your registered email and we will share the update password link with you</p>

                            <form className='gb-login-form' onSubmit={handleSubmit}>
                                {
                                    fields.map((field, index) => {
                                        return <GBInput
                                            key={index}
                                            name={field.name}
                                            type={field.type}
                                            label={field.label}
                                            value={field.value}
                                            placeholder={field.placeholder}
                                            setValue={handleChange}
                                            iconSrc={field.iconSrc}

                                        />
                                    })
                                }
                                <div className='gb-form-footer'>
                                    <button type='submit' className='form-submit'>
                                        {loading ?
                                            <>
                                                <div class="text-xs spinner-border text-light" style={{ width: '1rem', height: '1rem' }} role="status">
                                                </div>
                                                <span class="sr-only ml-5"> Loading...</span>
                                            </>
                                            :
                                            "Submit"
                                        }
                                    </button>
                                </div>
                            </form>
                            <Link to='/' className='button-login'>Sign In Now</Link>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    )
}

export default Forget